<template>
	<div>
		<!-- 导入excel -->
		<!-- <el-dialog :title="$t('i18nn_6c837fa18c154f3e')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1200px" top="0"> -->
		<el-drawer :wrapperClosable="false" :title="$t('i18nn_6c837fa18c154f3e')" append-to-body :visible.sync="dialogFile"
			:direction="'rtl'" size="1000px">
			<div class="top_action">
				<el-button type="primary" @click="downLoadPdf()"
					icon="el-icon-download"><span>{{$t('i18nn_f444ba8c0fa47753')}}</span>PDF</el-button>
				<el-button type="warning" @click="downLoadImg()"
					icon="el-icon-download"><span>{{$t('i18nn_f444ba8c0fa47753')}}</span>图片</el-button>
				<el-button type="success" @click="openConfig()"
					icon="el-icon-brush">{{$t('i18nn_784a9e148dbadc4a')}}</el-button>
			</div>
			<div class="mark_printer_con">
				<div class="mark_printer_title">
					<span>{{$t('i18nn_3b513604a77443db')}}</span>，<span>{{$t('i18nn_73bbf51487ec69e9')}}</span>{{priterData.length}}<span>{{$t('i18nn_0a8c7b53565de4f4')}}</span>
				</div>
				<div class="mark-item-con" v-loading="loading_load" id="markDom">
					<div class="mark-item" v-for="(item, index) in priterData" :key="index" :class="{
						'printer_size_1':'1'==form.size,
						'printer_size_2':'2'==form.size,
						'printer_size_3':'3'==form.size
					}">
						<div class="mark-logo"><img v-show="form.showLogo" class="logo-img" :src="$store.state.sysShow.logoInfo.logo"
								width="80" height="16.64" />
						</div>

						<div class="box-seq">
							Box&nbsp;{{ item.CtnNo }}&nbsp;of&nbsp;{{item.totalCTN}}
						</div>

						<div class="mark-name-con" v-show="form.showCus">
							<div class="mark-name-title">
								<span>Client</span>:
							</div>
							<div class="mark-name over_ellipsis">
								<span>
									<!-- {{ item.cusName }} -->
									<imgFont :text="item.cusName" :options="{
										width: 350,
										height: 35,
										fontSize: 30,
									}"></imgFont>
								</span>
							</div>
							<!-- <div>
								
								
								
							</div> -->
						</div>



						<div class="mark-sku-con" v-show="form.showSku">
							<div class="mark-sku-title">
								<span>SKU</span>:
							</div>
							<div class="mark-sku-name" :class="{
								'font-30':30==form.skuFontSize,
								'font-40':40==form.skuFontSize,
								'font-50':50==form.skuFontSize,
								'font-60':60==form.skuFontSize,
								'font-70':70==form.skuFontSize,
								'font-80':80==form.skuFontSize
							}">
								<span>{{ item.goodsSku }}</span>
							</div>

							<!-- <div>
								
							</div> -->
							<!-- <div class="mark-sku">CTN:{{item.totalCTN}}</div> -->
							<!-- <div class="mark-sku">PCS/CTN:&nbsp;<strong>{{ item.ctnPCS }}</strong></div> -->

						</div>

						<div class="mark-box-info">
							<!-- <div class="mark-total">Box:&nbsp;<strong>{{ item.CtnNo }}</strong>&nbsp;/&nbsp;<strong>{{ item.totalCTN }}</strong></div> -->
							<!-- <div class="mark-total">Total CTN:&nbsp;<strong>{{ item.totalCTN }}</strong></div> -->
							<!-- <div class="mark-total">Total PCS:&nbsp;<strong>{{ item.totalPCS }}</strong></div> -->
							<div class="mark-box-title">PCS:</div>
							<div class="mark-total">{{ item.ctnPCS }}</div>

							<!-- <div class="mark-total">Total CTN:&nbsp;<strong>{{ item.totalCTN }}</strong></div>
							<div class="mark-total">Total PCS:&nbsp;<strong>{{ item.totalPCS }}</strong></div> -->
						</div>

						<div class="mark-footer">
							<div class="mark-number">
								<span>No.</span>
								<span>{{ item.packingNo }}</span>
							</div>
							<div class="mark-code" v-show="form.showBarCode">
								<barcode :code="item.code"></barcode>
							</div>

							<div class="other-text">
								<span v-for="(item,index) in form.addText" :key="index">
									{{item}}
								</span>
							</div>
						</div>

					</div>
				</div>
			</div>

			<!-- <div slot="footer" class="dialog-footer"> -->
			<!-- <el-button type="primary" plain @click="dialogFile = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button> -->
			<!-- <el-button type="warning" plain icon="el-icon-refresh" @click="ChangeSelOrderRateExpressData()">{{$t('i18nn_a9e1d1c2c5eb6525')}}</el-button> -->
			<!-- </div> -->
		</el-drawer>
		<!-- </el-dialog> -->
		<!-- 配置设计 -->
		<el-drawer :wrapperClosable="false" :title="$t('i18nn_784a9e148dbadc4a')" append-to-body
			:visible.sync="dialogVisibleConfig" :direction="'rtl'" size="500px">
			<el-form ref="form" :rules="formRules" :model="form" label-width="120px" v-loading="loading"
				:element-loading-text="$t('i18nn_1b4525c800280581')">
				<el-form-item :label="$t('i18nn_a85af4ba66084d28')+'(mm)'">
					<el-radio-group v-model="form.size">
						<el-radio v-for="(item,index) in selectOption.sizeList" :key="index" :label="item.value">
							{{item.label}}
						</el-radio>
					</el-radio-group>
				</el-form-item>

				<el-form-item :label="'显示logo'">
					<el-switch v-model="form.showLogo" :active-text="$t('i18nn_02ccd2cf723f9272')"
						:inactive-text="$t('i18nn_e76d885ae1c74d4f')">
					</el-switch>
				</el-form-item>

				<el-form-item :label="$t('i18nn_a3ed58117fd81192')">
					<el-switch v-model="form.showCus" :active-text="$t('i18nn_02ccd2cf723f9272')"
						:inactive-text="$t('i18nn_e76d885ae1c74d4f')">
					</el-switch>
				</el-form-item>

				<el-form-item :label="'显示SKU'">
					<el-switch v-model="form.showSku" :active-text="$t('i18nn_02ccd2cf723f9272')"
						:inactive-text="$t('i18nn_e76d885ae1c74d4f')">
					</el-switch>
				</el-form-item>

				<el-form-item :label="'SKU字体大小'">
					<!-- <el-radio-group v-model="form.skuFontSize">
						<el-radio v-for="(item,index) in selectOption.skuFontSizeList" :key="index" :label="item.value">
							{{item.label}}
						</el-radio>
					</el-radio-group> -->
					<el-slider v-model="form.skuFontSize" :min="30" :max="80" :step="10" :marks="marks" show-stops>
					</el-slider>
				</el-form-item>

				<el-form-item :label="'显示编码'">
					<el-switch v-model="form.showBarCode" :active-text="$t('i18nn_02ccd2cf723f9272')"
						:inactive-text="$t('i18nn_e76d885ae1c74d4f')">
					</el-switch>
				</el-form-item>

				<el-form-item :label="$t('i18nn_ee7bdbe4f27e38df')">
					<el-checkbox-group v-model="form.addText">
						<el-checkbox v-for="(item,index) in selectOption.addTextList" :key="index" :label="item"></el-checkbox>
					</el-checkbox-group>
				</el-form-item>

			</el-form>
			<div class="drawer-footer">
				<el-button type="primary" plain @click="dialogVisibleConfig = false">{{ $t('FormMsg.Close') }}</el-button>
				<el-button type="primary" @click="submitForm()">{{$t('i18nn_204ffab8a6e01870')}}</el-button>
			</div>
		</el-drawer>
	</div>

</template>
<script>
	import {
		exportPdfByShippingMark,
		exportImgByShippingMark
	} from '@/utils/PdfShippingMark.js'
	import barcode from '@/components/Common/barcode.vue';
	import imgFont from '@/components/Common/imgFont.vue';
	export default {
		props: {
			openTime: {},
			row: {}
		},
		components: {
			barcode,
			imgFont
		},
		data() {
			return {
				dialogFile: false,
				// loadingUpload: false,
				loading: false,
				filterData: {
					id: ''
				},
				loading_load: false,
				priterData: [],

				selectOption: {
					sizeList: [
						// {
						// 	label: "100*60",
						// 	value: "1"
						// }, 
						{
							label: "210*210",
							value: "2"
						}, {
							label: "210*297(A4)",
							value: "3"
						}
					],
					// skuFontSizeList: [{
					// 	label: "30",
					// 	value: "30"
					// }, {
					// 	label: "40",
					// 	value: "40"
					// }, {
					// 	label: "50",
					// 	value: "50"
					// }, {
					// 	label: "60",
					// 	value: "60"
					// }],
					addTextList: ["Made in China"]
				},

				marks: {
					30: '30',
					40: '40',
					50: '50',
					60: '60',
					70: '70',
					80: '80',
				},

				dialogVisibleConfig: false,
				loading: false,
				form: {
					size: "2",
					showLogo: true,
					showCus: true,
					showSku: true,
					skuFontSize: 60,
					showBarCode: true,
					addText: []
				},
				formRules: {

				},
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialogFile = true;
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {

		},
		methods: {
			initData() {
				this.filterData.id = this.row.id;
				this.priterData = [];
				this.getPageData();
			},
			//下载pdf
			downLoadPdf() {
				this.loading_load = true;
				this.$nextTick(() => {
					exportPdfByShippingMark("#markDom", this.priterData, "BoxMark_" + this.row.packingNo, this.form);
					this.loading_load = false;
				});
			},
			//下载图片
			downLoadImg(){
				this.loading_load = true;
				this.$nextTick(() => {
					exportImgByShippingMark("#markDom", this.priterData, "BoxMark_Img_" + this.row.packingNo);
					this.loading_load = false;
				});
			},
			//打开设计
			openConfig() {
				this.dialogVisibleConfig = true;
			},
			//设计
			submitForm() {
				this.dialogVisibleConfig = false;
			},
			//重新格式化，计算箱唛，加同sku箱唛序号
			getFormatterData(data) {
				let newData = [];
				data.forEach(item => {
					let totalCTN = parseInt(item.totalCTN);
					if (1 == totalCTN) {
						item.CtnNo = 1;
						newData.push(item);
					} else {
						for (let i = 0; i < totalCTN; i++) {
							// console.log(arr[i]);
							let item_N = Object.assign({}, item);
							item_N.CtnNo = i + 1;
							// console.log('CtnNo',item_N.CtnNo);
							// let item_N = i+1;
							newData.push(item_N);
							// console.log('item_N',item_N);
							// console.log('newData',newData);
						}
					}
				});
				return newData;
			},
			//请求分页数据
			getPageData() {
				this.loading_load = true;
				this.$http
					.get(this.$urlConfig.WhPrinterShippingMark + '/' + this.filterData.id, {})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_776496ec446f21f9'));
						console.log(data);
						this.loading_load = false;
						if (200 == data.code && data.rows) {
							// this.priterData = data.rows;
							let tabelData = data.rows;
							this.priterData = this.getFormatterData(tabelData);
							console.log('priterData', this.priterData);
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('tips.queryErrorFailed'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('列表，请求失败');
						this.$message.error(this.$t('tips.queryRequestFailed'));
						this.loading_load = false;
					});
			}
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.top_action {
		padding: 10px;
	}

	.mark_printer_con {
		// font-family: Arial, Helvetica, sans-serif;
		// max-width: 1200px;
		// font-size: 12pt;
		// line-height: 120%;
		color: #000;
		background: #f5f5f5;
		padding: 10px 0;
		margin: 0 auto;
	}

	.printer_size_1 {
		width: 600px;
		height: 400px;
	}

	.printer_size_2 {
		width: 600px;
		height: 600px;
	}

	.printer_size_3 {
		width: 600px;
		height: 800px;
	}



	.mark_printer_title {
		font-size: 16px;
		line-height: 200%;
		padding-left: 20px;
		color: #999;
	}

	.mark-item-con {
		margin-top: 10px;
	}

	.mark-item {
		background: #fff;
		padding: 30px 30px;
		margin: 0 auto 10px auto;
		margin-bottom: 10px;
		page-break-after: always;
		width: 600px;

		.mark-logo {}

		.box-seq {
			font-size: 20px;
			line-height: 150%;
			text-align: right;
			margin-bottom: 20px;
			padding: 5px 0;
			border-bottom: 2px solid #000;
		}

		.mark-name-con {
			margin-top: 20px;
			line-height: 200%;
			// text-align: left;

		}

		.mark-name-title {
			font-size: 14px;
		}

		.mark-name {
			font-size: 20px;
			width: 370px;
		}

		.mark-sku-con {
			margin-top: 20px;
			line-height: 200%;
		}

		.mark-sku-title {
			font-size: 14px;
			line-height: 200%;
		}

		.mark-sku-name {
			font-size: 60px;
			line-height: 110%;
			width: 100%;
			height: 80px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.font-30 {
			font-size: 30px;
		}
		.font-40 {
			font-size: 40px;
		}
		.font-50 {
			font-size: 50px;
		}
		.font-60 {
			font-size: 60px;
		}
		.font-70 {
			font-size: 70px;
		}
		.font-80 {
			font-size: 80px;
		}

		.mark-box-info {
			margin-top: 20px;
			line-height: 200%;
		}

		.mark-box-title {
			font-size: 14px;
		}

		.mark-total {
			font-size: 28px;
		}



		.mark-footer {
			margin-top: 40px;
			padding: 5px 0;
			// border-top: 2px solid #000;
		}

		.mark-number {
			font-size: 20px;
			line-height: 150%;
			// margin-top: 50px;
		}

		.other-text {
			text-align: right;
			font-size: 14px;
			line-height: 150%;
			margin-top: 5px;
			// display: flex;
			// justify-content: space-around;
			// align-items: flex-end;
		}
	}
</style>