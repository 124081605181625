<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader"><h3 class="buyerHeaderTit">{{$t('Storage.nav.My_packing_list')}}</h3></div> -->
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{$t('Storage.nav.My_packing_list')}}</span>
						<el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
						</el-tooltip>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <ul class="actionConList"> -->
					<!-- <li> -->
					<el-button type="success" @click="toCreate" size="small"
						icon="el-icon-plus">{{$t('i18nn_e8564657bbe9ca53')}}</el-button>
					<!-- </li> -->
					<!-- <li v-if="'0'==filterData.status"> -->
					<el-button type="primary" @click="commitAction($event, null)" size="small" icon="el-icon-sell"
						v-if="'0'==filterData.status">{{$t('i18nn_ad4345dbaabe1479')}}</el-button>
					<!-- </li> -->
					<!-- <li v-if="''==filterData.status||'10'==filterData.status"> -->
					<el-button type="danger" @click="commitCancelAction($event, null)" size="small" icon="el-icon-sold-out"
						v-if="''==filterData.status||'10'==filterData.status">{{$t('i18nn_b4e5993f97fe04ee')}}</el-button>

					<!-- </li> -->
					<!-- </ul> -->

					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- <div style="text-align: center; margin-top: 10px;">
      <el-button-group>
        <el-button type="primary"  size="medium" style="width: 200px;">{{$t('i18nn_5b14580d813b4a45')}}</el-button>
        <el-button type="primary" plain size="medium" style="width: 200px;" @click="toPageUrl('PartnerBillSettlementDet')">{{$t('i18nn_1b613e926a1e65dd')}}</el-button>
      </el-button-group>
    </div> -->

		<div class="filterCon" style="">
			<div class="filterBarList">
				<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
					<el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">
						<span>
							<!-- {{ item.label }} -->
							{{i18nFormatter(item.value)}}
						</span>
					</el-menu-item>
				</el-menu>
			</div>
			<div class="filterAction">
				<!-- <ul class="actionConList">
					<li>
						<el-button type="success" @click="toCreate" size="small"
							icon="el-icon-plus">{{$t('i18nn_e8564657bbe9ca53')}}</el-button>
					</li>
					<li v-if="'0'==filterData.status">
						<el-button type="primary" @click="commitAction($event, null)" size="small"
							icon="el-icon-sell">{{$t('i18nn_ad4345dbaabe1479')}}</el-button>
					</li>
					<li v-if="''==filterData.status||'10'==filterData.status">
						<el-button type="danger" @click="commitCancelAction($event, null)" size="small"
							icon="el-icon-sold-out">{{$t('i18nn_b4e5993f97fe04ee')}}</el-button>

					</li>
				</ul> -->
				<ul class="filterConList">
					<!-- <li>
						<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser @change="changeAgentUser"></SelAgentUser>
					</li> -->
					<li>
						<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo"></whNoSelect>
					</li>
					<!-- <li>
						<span>{{$t('i18nn_f566f50ef680920c')}}</span>
						<el-select filterable clearable v-model="filterData.orderType" :placeholder="$t('FormMsg.Please_select')" size="small" style="width: 100px;" @change="initData()">
							<el-option v-for="item in selectOption.wh_work_order_type" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
						</el-select>
					</li> -->
					<!-- <li>
						<span class="filterName">{{$t('i18nn_43a3586339251494')}}</span>
						<el-date-picker v-model="filterData.commitDateArr" type="daterange" align="right" unlink-panels
							range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
							:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd"
							format="yyyy-MM-dd" size="medium" @change="initData()" :picker-options="pickerOptions">
						</el-date-picker>
					</li> -->
					<li>
						<span class="filterName">{{$t('i18nn_43a3586339251494')}}</span>
						<el-date-picker v-model="filterData.commitDateArr" type="daterange" align="right" unlink-panels
							range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
							:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd" format="yyyy-MM-dd" size="small"
							@change="initData()" :picker-options="pickerOptions" style="width: 220px">
						</el-date-picker>
					</li>
					
					<li>
						<span>{{$t('Storage.packing_list._no')}}</span>
						<el-input type="text" v-model="filterData.packingNo" size="small" clearable
							@keyup.enter.native="initData()" maxlength="300" :placeholder="$t('hytxs0000001')"
							style="width: 150px;" />
						<el-tooltip class="" effect="dark" :content="$t('i18nn_5b16dd13f1bc0e1c')"
							placement="top">
							<el-button  icon="el-icon-s-operation" size="small" type="success" plain
								@click="openMultiInputAction()"></el-button>
						</el-tooltip>
					</li>

					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;"></el-input>
					</li>

					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<!-- <li>
						<el-button :icon="drawerFilter?'el-icon-arrow-up':'el-icon-arrow-down'" size="small" type="primary"
							@click="openMoreFilter()"></el-button>
					</li> -->
				</ul>
				
				
				
			</div>
			<!-- 多柜号号搜索显示 -->
			<div class="" style="padding: 0 10px 10px 10px;"
				v-if="filterData.packingNoList && filterData.packingNoList.length > 0">
			
				<ul class="filterConList" style="text-align: left;">
					<el-button icon="el-icon-close" size="small" type="success"
						@click="clearMultiList()">
						<span>{{$t('i18nn_4c4e546aa3683ff5')}}</span>
						(<span>多柜号搜索</span>)
						(<span>共<span>{{filterData.packingNoList.length}}</span></span>)
					</el-button>
					<li v-for="(item, index) in filterData.packingNoList" :key="index">
						<el-tag size="small" effect="plain">{{ item }}</el-tag>
					</li>
				</ul>
			</div>
		</div>

		<!--更多筛选-->
		<!-- <el-drawer :wrapperClosable="false" custom-class="drawerFilter" :title="$t('i18nn_c4ca802cf2230b0b')" :visible.sync="drawerFilter"
			:direction="'rtl'"> -->
		<!-- <div class="drawerFilterCon" v-show="drawerFilter"> -->
		<!-- <ul class="drawerFilterList"> -->
		<!-- <li>
						<span class="filterName">{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser ref="SelAgentUser" @change="changeAgentUser" size="medium"></SelAgentUser>
					</li> -->
		<!-- <li>
						<span class="filterName">{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo" size="medium"></whNoSelect>
					</li> -->
		<!-- <li>
					<span class="filterName">{{$t('i18nn_43a3586339251494')}}</span>
					<el-date-picker v-model="filterData.commitDateArr" type="daterange" align="right" unlink-panels
						range-separator="-" :start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
						:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd" format="yyyy-MM-dd" size="small"
						@change="initData()" :picker-options="pickerOptions">
					</el-date-picker>
				</li>
				<li class="filterBtnCon">
					<el-button icon="el-icon-search" size="small" type="primary" @click="queryMoreFilter()">
						{{$t('i18nn_1e7246dd6ccc5539')}}</el-button>
				</li> -->
		<!-- </ul> -->
		<!-- </div> -->
		<!-- </el-drawer> -->

		<!-- <div class="wh-filter-con">
			<div class="">
			  <span>{{$t('Storage.packing_list.status')}}</span>
				<el-radio-group v-model="filterData.status" size="medium" @change="initData()">
				  <el-radio-button border v-for="item in selectOption.statusList" :key="item.value" :label="item.value">
						{{item.label}}
					</el-radio-button>
				</el-radio-group>
			</div>
		</div> -->
		<!-- <div class="wh-filter-con">
			<div class="wh-filter-item">
				<span>{{$t('i18nn_f77f473d27f48bdc')}}</span>
				<SelAgentUser ref="SelAgentUser" @change="changeAgentUser"></SelAgentUser>
			</div>
      <div class="wh-filter-item">
      	<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
				<whNoSelect @changeData="changWhNo"></whNoSelect>
      </div>
      <div class="wh-filter-item">
        <span>{{$t('Storage.packing_list._no')}}</span>
        <el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.packingNo" maxlength="50" :clearable="true" style="width: 150px;"></el-input>
      </div>
      <div class="wh-filter-item">
        <span>{{$t('i18nn_f353b1ebbdc25be4')}}</span>
        <el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword" maxlength="50" :clearable="true" style="width: 150px;">
        </el-input>
      </div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_6235565b185f0725')}}</span>
				<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.workNo" maxlength="50" :clearable="true" style="width: 220px;"></el-input>
			</div>
      <el-button type="primary" @click="initData" size="small">{{$t('FormMsg.Search')}}</el-button>
    </div> -->

		<!-- <div class="wh-action-con">
      <el-button type="success" @click="toCreate" size="small" icon="el-icon-upload">{{$t('Storage.packing_list.Import')}}</el-button>
			<el-button type="danger" @click="commitCancelAction($event, null)" size="small" icon="el-icon-sold-out">{{$t('i18nn_1a88bd0f0d22ca01')}}</el-button>
		</div> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small"
				:max-height="$store.state.frameConHeightWh1" @selection-change="handleSelectionChange"
				@row-click="handleCurrentChange">

				<el-table-column type="selection" fixed="left" align="center" width="55">
				</el-table-column>

				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>
				<!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->

				<!-- "id":"",
            "cusNo":"客户编码",
            "packageNo":"包装编号",
            "goodsSku":"产品代码",
            "goodsImg":"产品图片",
            "goodsName":"产品名称",
            "weightUnit":"重量单位",
            "goodsWeight":"单件重",
            "volumeUnit":"体积单位",
            "goodsLength":"长",
            "goodsWidth":"宽",
            "goodsHeight":"高",
            "goodsCount":"入库件数"

            "whFeeType":"仓租收费类型",

                "whFeeAmt":"仓租单件",-->

				<el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
					<template slot-scope="scope">
						<el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="success"
							v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="success"
							v-else-if="'15' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type=""
							v-else-if="'18' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="warning"
							v-else-if="'20' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<!-- <el-tag type="danger" v-else-if="'3'==scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag> -->
						<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					</template>
				</el-table-column>

				<el-table-column prop="receiptNo" :label="$t('i18nn_35ec768075eb8f4e')" min-width="120">
					<template slot-scope="scope">
						<div>

							<!-- <el-link type="primary" size="mini" icon="" @click="showDetList($event, scope.row, scope.$index)">
								{{ scope.row.receiptNo }}
							</el-link> -->
							<!-- <el-button type="text" @click="showDetList($event, scope.row, scope.$index)">
								{{ scope.row.receiptNo }}
							</el-button> -->
							<div class="link-copy">
								<span class="link-text" @click="showDetList($event, scope.row, scope.$index)">
									{{ scope.row.receiptNo }}
								</span>
								<el-tooltip class="link-copy-icon" effect="dark" :content="$t('i18nn_29f67f0644a78bf3')"
									placement="top">
									<i class="el-icon-document-copy" @click="$Utils.copyText(scope.row.receiptNo)"></i>
								</el-tooltip>
							</div>



						</div>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="workNo" :label="$t('i18nn_6235565b185f0725')"></el-table-column> -->
				<!-- <el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')"></el-table-column> -->
				<el-table-column prop="zoneWhNo" :label="$t('Storage.skuInfo.warehouse')"></el-table-column>
				<el-table-column prop="packingNo" :label="$t('Storage.packing_list._no')" min-width="150">
					<template slot-scope="scope">
						<!-- <div>
							<span>{{ scope.row.packingNo }}</span>
						</div> -->
						<div class="link-copy">
							<span class="">{{ scope.row.packingNo }}</span>
							<el-tooltip class="link-copy-icon" effect="dark" :content="$t('i18nn_29f67f0644a78bf3')" placement="top">
								<i class="el-icon-document-copy" @click="$Utils.copyText(scope.row.packingNo)"></i>
							</el-tooltip>&nbsp;
							<el-tooltip class="link-copy-icon" effect="dark" :content="'海柜路线查询'" placement="top"
								v-if="'1'==scope.row.packageType">
								<i class="el-icon-location-information" @click="$Utils.toSerCabLine(scope.row.packingNo)"></i>
							</el-tooltip>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="containerSizeName" :label="$t('i18nn_977ed6286b514ff6')"></el-table-column> -->
				<!-- <el-table-column prop="leavePort" :label="$t('Storage.packing_list.Departure_port')"></el-table-column> -->

				<!-- <el-table-column prop="arrivePort" :label="$t('Storage.packing_list.arrive_port')"></el-table-column> -->

				<!-- <el-table-column prop="leaveDate" :label="$t('Storage.packing_list.Departure_Date')"></el-table-column> -->



				<!-- <el-table-column prop="arriveDate" :label="$t('Storage.packing_list.arrive_Date')"></el-table-column> -->

				<!-- <el-table-column prop="arriveWhDate" :label="$t('i18nn_9be246e60da2a0a2')"></el-table-column> -->

				<!-- <el-table-column prop="receiveComplete" :label="$t('i18nn_e7b4fae7cec06141')"></el-table-column> -->

				<el-table-column prop="packageTypeName" :label="$t('Storage.packing_list.Type')" min-width="100">
					<template slot-scope="scope">
						<div class="recDet">
							<div class="recItem">
								{{scope.row.packageTypeName}}
							</div>
							<div class="recItem" v-if="scope.row.containerSizeName">
								<span>{{$t('i18nn_977ed6286b514ff6')}}</span>:<span
									style="padding-left: 5px;">{{scope.row.containerSizeName}}</span>
							</div>
							<div class="recItem" v-if="scope.row.isLiveUpload">
								<el-tag size="mini" v-if="'1'==scope.row.isLiveUpload">
									是现场卸货
								</el-tag>
								<el-tag size="mini" type="info" v-else-if="'0'==scope.row.isLiveUpload">
									非现场卸货
								</el-tag>
							</div>
						</div>
					</template>
				</el-table-column>


				<!-- <el-table-column prop="packCountTotal" :label="$t('i18nn_72d35d210669abb2')"></el-table-column>
				<el-table-column prop="verifyCountTotal" :label="$t('i18nn_4567a206c45dc3f4')">
					<template slot-scope="scope">
						<div v-if="scope.row.verifyCountTotal">
							<el-tag type="danger"
								v-if="scope.row.packCountTotal != scope.row.verifyCountTotal">{{ scope.row.verifyCountTotal }}</el-tag>
							<el-tag type="success" v-else>{{ scope.row.verifyCountTotal }}</el-tag>
						</div>
					</template>
				</el-table-column> -->

				<el-table-column prop="packCountTotal" :label="$t('i18nn_72d35d210669abb2')" min-width="120">
					<template slot-scope="scope">
						<div class="recDet">
							<div class="recItem"><span>数量</span>:<span
									style="font-size: 16px; font-weight: bold; color: #000; padding-left: 5px;">{{scope.row.packCountTotal}}</span>
							</div>
							<div class="recItem"><span>体积</span>:<span style="padding-left: 5px;">{{scope.row.packgeTotalVol}}</span>
							</div>
							<div class="recItem"><span>箱数</span>:
								<el-tag type="danger" effect="plain" size="mini" style=""
									v-if="scope.row.packCountTotal != scope.row.receviceTotalCtn">{{ scope.row.receviceTotalCtn }}</el-tag>
								<el-tag type="success" effect="plain" size="mini" style=""
									v-else>{{ scope.row.receviceTotalCtn }}</el-tag>
							</div>
							<div class="recItem"><span>托数</span>:
								{{scope.row.receviceTotalPallte}}
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="verifyCountTotal" :label="$t('i18nn_4567a206c45dc3f4')" min-width="120">
					<template slot-scope="scope">
						<div v-if="scope.row.verifyCountTotal" class="recDet">
							<div class="recItem">
								<span>数量</span>:
								<el-tag type="danger" effect="plain" size="mini" style="font-size: 16px;"
									v-if="scope.row.packCountTotal != scope.row.verifyCountTotal">{{ scope.row.verifyCountTotal }}</el-tag>
								<el-tag type="success" effect="plain" size="mini" style="font-size: 16px;"
									v-else>{{ scope.row.verifyCountTotal }}</el-tag>
							</div>
							<div class="recItem"><span>体积</span>:
								<!-- {{scope.row.receviceTotalVol}} -->
								<el-tag type="danger" size="mini"
									v-if="scope.row.packgeTotalVol != scope.row.receviceTotalVol">{{ scope.row.receviceTotalVol }}</el-tag>
								<el-tag type="success" size="mini" v-else>{{ scope.row.receviceTotalVol }}</el-tag>
							</div>
							<div class="recItem"><span>打托</span>:
								{{scope.row.inWhPallte}}
							</div>
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="packRecordList" :label="'SKU'+'*'+'预报'+'*'+'实收'+'*'+'上架'" min-width="220">
					<template slot-scope="scope">
						<div v-if="scope.row.packRecordList" class="recDet">
							<div class="recItem" v-for="(item,index) in scope.row.packRecordList" :key="index" v-if="index<5">
								<span v-if="'20'==scope.row.status">
									<el-tag type=""  effect="plain" size="mini"
										v-if="'30' == item.status">{{ $Utils.i18nKeyText(item,'statusName') }}</el-tag>
									<el-tag type="success" effect="plain"  size="mini"
										v-else-if="'40' == item.status">{{ $Utils.i18nKeyText(item,'statusName') }}</el-tag>
									<el-tag type="info" effect="plain" size="mini" v-else>{{ $Utils.i18nKeyText(item,'statusName') }}</el-tag>
								</span>
								
								<span class="recItem-sku">{{item.goodsSku}}</span>&nbsp;*&nbsp;
								<strong class="recItem-num">{{item.packCount}}</strong>&nbsp;*&nbsp;
								<strong class="recItem-num">{{item.verifyCount}}</strong>
								<span v-if="'20'==scope.row.status">
									&nbsp;*&nbsp;
									<strong class="recItem-num" :class="item.verifyCount==item.inWhCount?'':'red'">
										{{item.inWhCount?item.inWhCount:0}}
									</strong>
								</span>
								
							</div>
							<div v-if="scope.row.packRecordList.length>5">
								<el-link type="primary" @click="showDetList($event, scope.row, scope.$index)">
									..
									<span>{{$t('i18nn_73bbf51487ec69e9')}}</span>
									<span>&nbsp;{{scope.row.packRecordList.length}}&nbsp;</span>
									<span>{{$t('i18nn_117aa3f27e5e430c')}}</span>
								</el-link>
							</div>
						</div>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="inWhPallte" :label="$t('i18nn_197e2039cf365afa')"></el-table-column> -->

				<!-- <el-table-column prop="isLiveUploadName" :label="$t('i18nn_e137950d1897817e')">
					<template slot-scope="scope">
						<div>
							{{ $Utils.i18nKeyText(scope.row,'isLiveUploadName') }}
						</div>
					</template>
				</el-table-column> -->

				<!-- <el-table-column prop="goodsSku" label="SKU"></el-table-column>

        <el-table-column prop="cusCode" :label="$t('i18nn_64fed2851f79d875')"></el-table-column>

        <el-table-column prop="goodsName" :label="$t('i18nn_b23004db5349dfd2')"></el-table-column>

        <el-table-column prop="goodsNameEn" :label="$t('i18nn_05141ac0139eaf01')"></el-table-column>

        <el-table-column prop="goodsImg" :label="$t('Storage.skuInfo.SKU_pictures')">
          <template slot-scope="scope">
            <div>
              <el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.goodsImg" @click="openBigImg($event, scope.row.goodsImg)">
                <div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i></div>
              </el-image>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="defCode" :label="$t('i18nn_791e36963e37617a')"></el-table-column>
        <el-table-column prop="hashCode" :label="$t('i18nn_198738759379a3c0')"></el-table-column>
        <el-table-column prop="declareNameCh" :label="$t('i18nn_73d237ea940222ce')"></el-table-column>
        <el-table-column prop="declareNameEn" :label="$t('i18nn_9894786aad05f849')"></el-table-column>
        <el-table-column prop="declarePrice" :label="$t('i18nn_fe28507421c4c3b3')"></el-table-column>
        <el-table-column prop="goodsWeight" :label="$t('i18nn_11e41b2ec3d872ed')"></el-table-column>
        <el-table-column prop="weightUnitName" :label="$t('i18nn_8e01866868930d09')"></el-table-column>
        <el-table-column prop="goodsLength" :label="$t('Storage.skuInfo.length')"></el-table-column>
        <el-table-column prop="goodsWidth" :label="$t('Storage.skuInfo.width')"></el-table-column>
        <el-table-column prop="goodsHeight" :label="$t('Storage.skuInfo.height')"></el-table-column>
        <el-table-column prop="volumeUnitName" :label="$t('i18nn_57936a4767ded7f5')"></el-table-column> -->

				<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover">
							<div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>

				<el-table-column prop="attachments" :label="$t('Storage.packing_list.attachment')" class="" min-width="120">
					<template slot-scope="scope">

						<div class="recDet">
							<div class="recItem" v-if="scope.row.attachments">
								<div @click="openUploadFile(scope.row)">
									<el-link type="primary" icon="el-icon-paperclip">{{$t('Storage.packing_list.attachment')}}</el-link>
									<el-badge :value="scope.row.attachments.length" type="primary">
									</el-badge>
								</div>
							</div>
							<div class="recItem" v-if="scope.row.receivingList">
								<div @click="openReceiptList(scope.row)">
									<el-link type="primary" icon="el-icon-paperclip">{{ $t('i18nn_d8d0c7c9b9ee194d') }}</el-link>
									<el-badge :value="scope.row.receivingList.length" type="primary">
									</el-badge>
								</div>
							</div>
						</div>

						<!-- <div @click="openUploadFile(scope.row)">
							<div v-if="scope.row.attachments">
								<el-link
									type="primary">...<span>{{$t('i18nn_73bbf51487ec69e9')}}</span><span>{{scope.row.attachments.length}}</span><span>{{$t('i18nn_7f18cb0ceb569eb0')}}</span></el-link>
							</div>
						</div> -->
					</template>
				</el-table-column>

				<!-- <el-table-column prop="receivingList" :label="$t('i18nn_d8d0c7c9b9ee194d')">
					<template slot-scope="scope">
						<div @click="openReceiptList(scope.row)">
							<div v-if="scope.row.receivingList">
								<el-link type="primary">
									...
									<span>{{ $t('73bbf51487ec69e9') }}</span>
									<span>{{ scope.row.receivingList.length }}</span>
									<span>{{ $t('i18nn_7f18cb0ceb569eb0') }}</span>
								</el-link>
							</div>
						</div>
					</template>
				</el-table-column> -->

				<!-- <el-table-column prop="commitDate" :label="$t('i18nn_0ae081c3b8c4d4a1')"></el-table-column> -->

				<el-table-column prop="" :label="$t('i18nn_43a3586339251494')" min-width="220">
					<template slot-scope="scope">
						<div>
							<ul>
								<li>
									<span>{{$t('i18nn_0ae081c3b8c4d4a1')}}</span>:{{scope.row.commitDate}}
								</li>
								<li>
									<span>{{$t('i18nn_9be246e60da2a0a2')}}</span>:{{scope.row.arriveWhDate}}
								</li>
								<li>
									<span>{{$t('i18nn_e7b4fae7cec06141')}}</span>:{{scope.row.receiveComplete}}
								</li>
							</ul>
						</div>
					</template>
				</el-table-column>

				<el-table-column :label="$t('Storage.tableColumn.operation')" width="280px" fixed="right" align="left">
					<template slot-scope="scope">

						<!-- <div v-if="isSel"><el-button @click="selRow($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-magic-stick">{{$t("FormMsg.Select")}}</el-button></div> -->
						<!-- <div v-else> -->
						<!-- <el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button> -->
						<!-- <el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button> -->
						<!-- </div> -->
						<!-- <el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button> -->
						<div>
							<!-- <div style="margin-bottom: 10px;" v-if="'0' == scope.row.status">
                <el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button>
                <el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button>
              </div> -->
							<div style="margin-bottom: 5px;">

								<el-button @click="commitAction($event, scope.row, scope.$index)" type="primary" size="small"
									icon="el-icon-sell" v-if="'0' == scope.row.status">{{$t('Storage.DropShipping.submit')}}</el-button>

								<el-button @click="delAction($event, scope.row)" type="danger" size="small" icon="el-icon-minus"
									v-if="'0' == scope.row.status">
									{{$t("FormMsg.Delete")}}</el-button>

								<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="small"
									icon="el-icon-edit" v-if="'0' == scope.row.status || '10' == scope.row.status">
									{{$t("FormMsg.Edit")}}</el-button>

								<el-button @click="commitCancelAction($event, scope.row, scope.$index)" type="danger" size="small"
									icon="el-icon-sold-out" v-if="'10' == scope.row.status">{{$t('i18nn_b4e5993f97fe04ee')}}</el-button>



							</div>

							<div>


								<el-dropdown size="small" @command="rowMoreAction($event, scope.row, scope.$index)">
									<el-button type="primary" size="small" style="margin-right:10px;">
										<span>{{$t('i18nn_c3525eb110db58aa')}}</span><i class="el-icon-arrow-down el-icon--right"></i>
									</el-button>
									<el-dropdown-menu slot="dropdown">

										<el-dropdown-item command="openUploadFile" icon="el-icon-upload2">
											{{$t('FormMsg.upload_attachment')}}
										</el-dropdown-item>
										<el-dropdown-item divided command="openShippingMark" icon="el-icon-download">
											{{$t('i18nn_6c837fa18c154f3e')}}
										</el-dropdown-item>
										<el-dropdown-item divided command="openReceiptList"
											icon="el-icon-paperclip">{{$t('i18nn_d8d0c7c9b9ee194d')}}</el-dropdown-item>

										<el-dropdown-item divided command="openQARecList" icon="el-icon-service">
											{{$t('i18nn_10bc8a2b45aa5636')}}
										</el-dropdown-item>

									</el-dropdown-menu>
								</el-dropdown>

								<el-button @click="copyAction($event, scope.row, scope.$index)" type="success" size="small"
									icon="el-icon-copy-document"
									v-if="'0' == scope.row.status || '10' == scope.row.status">{{$t('i18nn_29f67f0644a78bf3')}}</el-button>
							</div>



							<!-- <div style="margin-bottom: 5px;">
                <el-button @click="openUploadFile($event, scope.row)" type="success" size="mini" icon="el-icon-upload2">{{$t('FormMsg.upload_attachment')}}</el-button>
              </div> -->

							<!-- <div v-if="'0' == scope.row.status">
                <el-button @click="shipmentsAction($event, scope.row, scope.$index)" type="success" size="mini" icon="el-icon-sell">{{$t('Storage.packing_list.shipments')}}</el-button>
              </div> -->


							<!-- <div  style="margin-bottom: 5px;" >
							  <el-button @click="openShippingMark($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-printer">{{$t('i18nn_6c837fa18c154f3e')}}</el-button>
								<el-button @click="openReceiptList($event, scope.row, scope.$index)" type="primary" size="mini" icon="el-icon-view">{{$t('i18nn_6f153d59e22a769f')}}</el-button>
							</div>
							
							<div style="margin-bottom: 5px;">
								<el-button @click="openQARecList($event, scope.row)" type="primary" plain size="mini" icon="el-icon-service">{{$t('i18nn_10bc8a2b45aa5636')}}</el-button>
							</div> -->

						</div>


					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination"><hy-page-pagination :pagination="pagination"
					v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>
		
		<!-- 柜号输入框 -->
		<el-dialog :title="$t('Storage.packing_list._no')" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogMultiInputVisible" width="1000px">
			<div style="">
				<h3>{{$t('i18nn_380912455c6e2f06')}}</h3>
				<el-input type="textarea" :autosize="{ minRows: 20 }" v-model="filterData.packingNoListStr" size="small"
					clearable show-word-limit maxlength="10000" :placeholder="'一行输入一个'"
					style="width: 100%;vertical-align: middle; " />
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogMultiInputVisible = false">{{ $t('4e9fc68608c60999') }}
				</el-button>
				<el-button type="primary" @click="sureMultiInputAction()">{{$t('i18nn_b5511889be42a3da')}}</el-button>
			</div>
		</el-dialog>

		<!-- 箱唛 -->
		<ShippingMark :openTime="openTimeShippingMark" :row="selShippingMarkData" @actionSuccess="ShippingMarkSuccess">
		</ShippingMark>

		<!-- 收货清单 -->
		<whFileView :openTime="openTimeReceiptList" :row="selReceiptListData" :title="$t('i18nn_a7e730af52ab52b1')"
			:typeCode="'60'"></whFileView>

		<!-- 售后 -->
		<QAModelRecList :openTime="QAMoRecListOpenTime" :type="'40'" :relationId="QAMoRecListRelationId"
			:relationNo="QAMoRecListRelationNo" :whNo="QAMoRecListWhNo" @success="QAMoRecListSuccess"></QAModelRecList>

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
      <div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogImgVisible = false">{{$t('FormMsg.Close')}}</el-button></div>
    </el-dialog> -->
		<!--查看明细-->
		<!-- <el-dialog :title="$t('FormMsg.details_View')" append-to-body :close-on-click-modal="false" :visible.sync="dialogShowDet" width="1200px" top="0"> -->
		<!-- <el-drawer :wrapperClosable="false" :title="$t('FormMsg.details_View')" append-to-body :visible.sync="dialogShowDet"
			:direction="'rtl'" size="1200px">
			<div style=""> -->
		<InWarehouseDetList :openTime="openTimeDet" :row="rowDet"></InWarehouseDetList>
		<!-- </div> -->
		<!-- <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogShowDet = false">{{$t('FormMsg.Close')}}</el-button></div> -->
		<!-- </el-drawer> -->
		<!-- </el-dialog> -->
		<!--  附件上传 -->
		<whFileUpload :openTime="FileUploadOpenTime" :relationId="fileRelationId" @success="FileUploadSuccess">
		</whFileUpload>

		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData" :pagination="{}"
			:excelName="excelName" :excelOption="excelOption" :expHttpUrl="$urlConfig.WhPackingListPageList"
			:expHttpFilter="pageFilterData()" :noShowExpDefExcel="true" :hasFormatter="true" :formatterData="formatterData">
		</whExcelCustom>
	</div>
</template>
<script>
	import {
		InWarehouse_status
	} from '@/i18n/langStatus.js';
	import {
		i18nStatus
	} from '@/i18n/FormatI18n.js';

	import ShippingMark from '@/components/StorageCenter/components/ShippingMark.vue';
	import InWarehouseDetList from '@/components/StorageCenter/clp/InWarehouseDetList.vue';
	import QAModelRecList from '@/components/StorageCenter/QA/QAModelRecList.vue';
	// import InWarehouseReceiptList from '@/components/StorageCenter/clp/InWarehouseReceiptList.vue';
	import whFileView from '@/components/StorageCenter/components/whFileView.vue';
	import whFileUpload from '@/components/StorageCenter/components/whFileUpload.vue';
	import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';
	export default {

		components: {
			ShippingMark,
			InWarehouseDetList,
			whFileView,
			whFileUpload,
			SelAgentUser,
			whNoSelect,
			QAModelRecList,
			whExcelCustom
		},
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: '',
				excelOption: {},

				// dialogShowDet: false,
				openTimeDet: '',
				rowDet: {},
				
				fileRelationId: '',
				FileUploadOpenTime: '',
				//箱唛
				openTimeShippingMark: '',
				selShippingMarkData: {},
				//收货清单
				openTimeReceiptList: '',
				selReceiptListData: {},
				//柜号输入弹窗
				dialogMultiInputVisible: false,

				//售后
				QAMoRecListOpenTime: "",
				QAMoRecListRelationId: "",
				QAMoRecListRelationNo: "",
				// QAMoRecListUserId:"",
				QAMoRecListWhNo: "",
				// activeName: 'second',
				// isShowFrom:false,
				pickerOptions: this.$PickerDate.pickerOptions_1(),

				drawerFilter: false,

				multipleSelection: [],
			

				loading: false,

				loading_det: false,

				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					// wh_no: [],
					// wh_vol_unit: [],
					// wh_goods_fee_type: [],
					wh_size_unit: [],
					wh_packing_type: [],
					wh_container_size: [],
					statusList: InWarehouse_status
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序
					// "accountPeriod":"",
					// "billNo":""
					agentUser: "",
					// workNo:'',
					commitDateArr: [],
					status: '',
					packingNo: '',
					packingNoList: [],
					packingNoListStr: '',
					keyword: "",
					whNo: ''
					// putWhNo: '',
					// goodsSku: '',
					// hashCode: '',
					// goodsName: '',
					// declareNameCh: ''
				}
			};
		},

		//创建时
		created() {
			// this.getPageData();
			// if(this.$route.query.workNo){
			// 	this.filterData.workNo = this.$route.query.workNo;
			// 	// this.initData();
			// }
			if (this.$route.query.state) {
				this.filterData.status = this.$route.query.state;
				// this.initData();
			}
			if (this.$route.query && this.$route.query.agentUser) {
				this.filterData.agentUser = this.$route.query.agentUser;
				this.$nextTick(() => {
					this.$refs.SelAgentUser.initData(this.filterData.agentUser);
				});
			}
		},
		//编译挂载前
		mounted() {
			// this.getDicData();

			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				this.multipleSelection = [];
				this.getPageData();
				// this.getDicData();
			},
			//打开更多筛选
			openMoreFilter() {
				this.drawerFilter = !this.drawerFilter;
			},
			//查询更多筛选
			queryMoreFilter() {
				this.initData();
				// this.drawerFilter = false;
			},
			i18nFormatter(value) {
				return i18nStatus(value, this.selectOption.statusList)
			},
			changeAgentUser(val) {
				this.filterData.agentUser = val;
				this.initData();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},


			//导出 excel
			exportExcel() {
				let columns = [{
						title: this.$t('Storage.skuInfo.warehouse'),
						key: 'whNo',
					},
					{
						title: this.$t('Storage.packing_list.status'),
						key: 'statusName'
					},
					{
						title: this.$t('i18nn_35ec768075eb8f4e'),
						key: 'receiptNo'
					},

					{
						title: this.$t('Storage.packing_list._no'),
						key: 'packingNo'
					},
					{
						title: this.$t('Storage.packing_list.Type'),
						key: 'packageTypeName'
					},
					{
						title: this.$t('i18nn_977ed6286b514ff6'),
						key: 'containerSizeName'
					},
					{
						title: this.$t('i18nn_72d35d210669abb2'),
						key: 'packCountTotal'
					},
					{
						title: this.$t('i18nn_4567a206c45dc3f4'),
						key: 'verifyCountTotal'
					},
					{
						title: '预报体积',
						key: 'packgeTotalVol'
					},
					{
						title: '实收体积',
						key: 'receviceTotalVol'
					},
					{
						title: this.$t('i18nn_197e2039cf365afa'),
						key: 'inWhPallte'
					},
					{
						title: this.$t('Storage.tableColumn.remark'),
						key: 'remark'
					},
					{
						title: this.$t('i18nn_0ae081c3b8c4d4a1'),
						key: 'commitDate'
					},
					{
						title: this.$t('i18nn_9be246e60da2a0a2'),
						key: 'arriveWhDate'
					},
					{
						title: this.$t('i18nn_e7b4fae7cec06141'),
						key: 'receiveComplete'
					},
					//明细
					{
						title: 'SKU',
						key: 'goodsSku'
					},
					{
						title: this.$t('i18nn_bad53577db0da2d3'),
						key: 'packCount'
					},
					{
						title: this.$t('Storage.packing_list.Check_number'),
						key: 'verifyCount'
					},
					{
						title: this.$t('i18nn_e7b838a996147e21'),
						key: 'totalCTN'
					},
					{
						title: this.$t('i18nn_83125000f5142ed1'),
						key: 'totalPallet'
					},
					{
						title: this.$t('i18nn_85a10d3e5ea0e103'),
						key: 'ctnPCS'
					},
					{
						title: this.$t('i18nn_b6bf0a07fe26f74f'),
						key: 'ctnSizeUnitName'
					},
					{
						title: this.$t('i18nn_89d99a1a68608f3b'),
						key: 'ctnWeight'
					},
					{
						title: this.$t('i18nn_913e044950ff558b') + 'L*W*H',
						key: 'ctnLWH'
					},


				];
				let Data = this.tableData;

				this.expExcelData = Data;
				this.excelHead = columns;
				this.excelName = 'InWarehouse';
				this.excelOption = {
					height: 20,
					hasShowMerge: true,
					mergeLengthKey: 'packRecordList',
					mergeDataCol: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'],
				};
				this.excelOpenTime = new Date().getTime();

				// excelUtilsNew.exportExcel(columns, Data, 'WhDropShipping', { height: 20 });
			},

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},
			
			//打开订单的输入框
			openMultiInputAction() {
				// this.filterData.orderNumberListStr = "";
				this.dialogMultiInputVisible = true;
			},
			//确定订单的输入框
			sureMultiInputAction() {
				this.dialogMultiInputVisible = false;
				this.filterData.packingNo = '';
				let packingNoList = [];
				if (this.filterData.packingNoListStr) {
					packingNoList = this.filterData.packingNoListStr.split('\n').filter(function(s) {
						return s && s.trim();
					});
				}
				this.filterData.packingNoList = packingNoList;
				this.initData();
			},
			//清除搜索订单号的值
			clearMultiList() {
				this.filterData.packingNoListStr = '';
				this.filterData.packingNoList = [];
				this.initData();
			},

			//行操作更多
			rowMoreAction(command, row, index) {
				console.log('rowMoreAction', command);
				// event.stopPropagation();
				if ('openUploadFile' == command) {
					this.openUploadFile(row, index);
				} else if ('openShippingMark' == command) {
					this.openShippingMark(row, index);
				} else if ('openReceiptList' == command) {
					this.openReceiptList(row, index);
				} else if ('openQARecList' == command) {
					this.openQARecList(row, index);
				}


				// else if('toExpLgsTrackView'==command){
				// 	this.toExpLgsTrackView(row,index);
				// }
			},

			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//打开新增编辑，弹窗
			// openDioalog(formParm, msg) {
			//   // console.log(formParm);
			//   this.dialogFormVisible = true;
			//   let form = Object.assign({}, formParm);
			//   console.log('form', form);
			//   this.dialogFormMsg = msg;
			//   // // 重置
			//   this.resetForm('form');
			//   if (null === formParm) {
			//     //新增

			//     // this.form.weightUnit = '1';
			//     // this.form.volumeUnit = '1';
			//     // this.form.whFeeType = '1';

			//     this.dialogFormStatus = 0;
			//     // form.subUserId = null;
			//     // form.userSubUserId = null;
			//     // form.state = true;
			//     //浅拷贝、对象属性的合并
			//     this.form = form;
			//   } else {
			//     //修改
			//     this.dialogFormStatus = 1;
			//     // form.state = form.state === '0' ? true : false;

			//     //浅拷贝、对象属性的合并
			//     this.form = form;
			//   }

			//   this.$forceUpdate();
			//   // this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			//   //       type: 'warning',
			//   //       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//   //     });
			// },
			//打开编辑
			openEdit(event, row, index) {
				event.stopPropagation();
				// this.openDioalog(row, this.$t('FormMsg.Edit'));
				// this.getPageDetData(row.id);
				this.$router.push({
					name: "createOrder",
					query: {
						orderType: '60',
						single: true,
						id: row.id
					}
				})
			},

			//复制
			copyAction(event, row, index) {
				event.stopPropagation();
				// this.openDioalog(row, this.$t('FormMsg.Edit'));
				// this.getPageDetData(row.id);
				this.$router.push({
					name: "createOrder",
					query: {
						// cusUserId:row.userId,
						orderType: '60',
						single: true,
						id: row.id,
						copy: '1'
					}
				})
			},

			//明细
			//请求分页数据
			getPageDetData(packId) {
				// let _this = this;
				this.loading_det = true;
				this.$http
					.put(this.$urlConfig.WhPackingListDetPageList, {
						// "sortAsc": this.filterData.sortAsc,
						// "orderBy": this.filterData.orderBy,
						offset: 0, //当前页
						limit: 1000, //当前页显示数目

						packId: packId
						// putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
						// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
						// hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
						// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
						// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
					})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);

						this.loading_det = false;
						//表格显示数据
						this.form.packRecordList = data.rows;
						//当前数据总条数
						// this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_det = false;
					});
			},

			//箱唛
			openShippingMark(row) {
				// event.stopPropagation();
				this.selShippingMarkData = row;
				this.openTimeShippingMark = new Date().getTime();
			},

			//箱唛成功回调
			ShippingMarkSuccess(data) {
				this.initData();
			},

			//查看收货清单
			openReceiptList(row) {
				// event.stopPropagation();
				this.selReceiptListData = row;
				this.openTimeReceiptList = new Date().getTime();
			},

			//打开附件上传
			openUploadFile(row) {
				// event.stopPropagation();
				// this.isShowFileUpload = true;
				this.fileRelationId = row.id;
				this.FileUploadOpenTime = new Date().getTime();
			},
			//附件上传成功回调
			FileUploadSuccess(data) {
				this.initData();
				// event.stopPropagation();
				// this.isShowFileUpload = true;
				// this.fileRelationId = row.id;
				// this.FileUploadOpenTime = new Date().getTime();
			},

			//撤销发货
			commitCancelAction(event, row) {
				event.stopPropagation();

				let parm = [];

				if (row) {
					//     //单条
					parm = [row.id];
				} else {
					//多条
					let dataList = this.multipleSelection;
					if (dataList.length < 1) {
						this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
						return;
					}
					if (dataList.findIndex(item => '10' != item.status) > -1) {
						this.$message.warning(this.$t('tips.long_InWarehouse_1'));
						return;
					}

					let dataListParm = dataList.map(v => v.id);
					parm = dataListParm;
					// console.log('dataListParm', dataListParm);
					// let dataParm = {
					//   ids: dataListParm
					// };
				}

				this.$confirm(this.$t('Storage.DropShipping.sure_submit_1') + parm.length + this.$t(
						'Storage.DropShipping.sure_submit_2'), this.$t('tips.tipsTitle'), {
						// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {
						// {"ids":[11111111111111]}
						this.postData(this.$urlConfig.WhMyGoodsPackingCancel, {
							"ids": parm
						}, () => {
							this.$message.success(this.$t('tips.submitSuccess'));
							// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							// 		type: 'success'
							// 	});
						});
					})
					.catch(() => {});
			},

			//售后
			openQARecList(row) {
				// event.stopPropagation();
				this.QAMoRecListRelationId = row.id;
				this.QAMoRecListRelationNo = row.receiptNo;
				// this.QAMoRecListUserId = row.userId;
				this.QAMoRecListWhNo = row.whNo;
				this.QAMoRecListOpenTime = new Date().getTime();
			},
			QAMoRecListSuccess() {
				this.initData();
			},

			//打印箱唛
			// toPrinterShippingMark(event, row){
			// 	event.stopPropagation();
			// 	// this.$router.push({name:"ShippingMark"});
			// 	// this.PrinterOpenTime = new Date().getTime();
			// 	// this.PrinterData = [];
			// 	let routeData = this.$router.resolve({ name: 'ShippingMark', query: { id: row.id } });
			// 	window.open(routeData.href, '_blank');
			// },

			//删除
			delAction(event, row) {
				event.stopPropagation();
				this.$confirm(this.$t("FormMsg.confirm_Delete"), this.$t('tips.tipsTitle'), {
						// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {
						// this.delDataAction(event, row);
						this.postData(this.$urlConfig.WhPackingListDel + '/' + row.id, {}, () => {
							this.$message.success(this.$t('tips.successfullyDelete'));
						}, 'delete');
					})
					.catch(() => {

					});
			},

			//提交
			commitAction(event, row) {
				event.stopPropagation();

				let parm = [];

				if (row) {
					//     //单条
					parm = [row.id];
				} else {
					//多条
					let dataList = this.multipleSelection;
					if (dataList.length < 1) {
						this.$message.warning(this.$t('Storage.DropShipping.sel_tips_1'));
						return;
					}
					if (dataList.findIndex(item => '0' != item.status) > -1) {
						this.$message.warning("只有全部为 草稿 状态数据才可操作");
						return;
					}

					let dataListParm = dataList.map(v => v.id);
					parm = dataListParm;
					// console.log('dataListParm', dataListParm);
					// let dataParm = {
					//   ids: dataListParm
					// };
				}

				this.$confirm(this.$t('Storage.DropShipping.sure_submit_1') + parm.length + this.$t(
						'Storage.DropShipping.sure_submit_2'), this.$t('tips.tipsTitle'), {
						// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
						type: 'warning'
					})
					.then(() => {
						this.postData(this.$urlConfig.WhPackingListCommit, {
							ids: parm
						}, () => {
							this.$message.success(this.$t('tips.submitSuccess'));
						});
					})
					.catch(() => {});
			},
			//删除
			// delDataAction(event, row) {
			//   event.stopPropagation();
			//   console.log('delDataAction', row);
			//   // let parm = [];

			//   //   if (!!row) {
			//   //     //单条
			//   //     parm = [row.id];
			//   //   } else {
			//   //     //多条
			//   // //     let dataList = this.multipleSelection;
			//   // //     if (dataList.length < 1) {
			//   // //       this.$message.warning(this.$t('i18nn_7b80e66b535a3732'));
			//   // //       return;
			//   // //     }

			//   // //     let dataListParm = dataList.map(v => v.id);
			//   // //     parm = dataListParm;
			//   //     // console.log('dataListParm', dataListParm);
			//   //     // let dataParm = {
			//   //     //   ids: dataListParm
			//   //     // };
			//   //   }

			//   this.postData(this.$urlConfig.WhPackingListDel + '/' + row.id, {}, 'delete');
			// },

			//发货
			// shipmentsAction(event, row) {
			//   this.$confirm('确定发货吗?', this.$t('tips.tipsTitle'), {
			//     // confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     // cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			//     type: 'warning'
			//   })
			//     .then(() => {
			//       // this.$message({
			//       //   type: 'success',
			//       //   message: '删除成功!'
			//       // });
			//       // this.delDataAction(event, row);
			//       this.postData(this.$urlConfig.WhPackingListShipments + '/' + row.id, {}, () => {
			//         // this.initData();
			//         //  this.$alert('this.$t('tips.submitSuccess')'tips.submitSuccess')', this.$t('tips.tipsTitle'), {
			//         //   type: 'success',
			//         //   confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//         // });
			//         this.$message.success(this.$t('tips.submitSuccess'));
			// 		// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
			// 		// 		type: 'success'
			// 		// 	});
			//       });
			//     })
			//     .catch(() => {
			//       // this.$message({
			//       //   type: 'info',
			//       //   message: this.$t('i18nn_2e58cb9b52e2a214')
			//       // });
			//     });
			// },

			//打开选择数据
			// openSelData(event, row, index) {
			//   event.stopPropagation();

			//   this.dialogSelVisible = true;
			//   this.openRowData = row;
			//   this.openRowIndex = index;
			//   // this.$nextTick(() => {
			//   //   this.$refs.hyUpLoadImg1.openFile();
			//   // });
			// },
			//选择数据后回调
			// selWhGoodsData(selRow) {
			//   this.dialogSelVisible = false;
			//   console.log('selRow', selRow);
			//   let row = this.openRowData;
			//   // row.putWhNo = selRow.putWhNo;
			//   // row.plPlaceNo = selRow.placeNo;
			//   row.goodsSku = selRow.goodsSku;
			//   row.goodsImg = selRow.goodsImg;
			//   row.goodsName = selRow.goodsName;
			//   // row.goodsId = selRow.id;

			//   // row.cusCode = selRow.cusCode;
			//   row.goodsWeight = selRow.goodsWeight;
			//   // row.weightUnit = selRow.weightUnit;
			//   row.goodsLength = selRow.goodsLength;
			//   row.goodsWidth = selRow.goodsWidth;
			//   row.goodsHeight = selRow.goodsHeight;
			//   row.sizeUnit = selRow.sizeUnit;

			//   row.safeStock = selRow.safeStock;

			//   // row.defCode = selRow.defCode;
			//   // row.hashCode = selRow.hashCode;
			//   // row.declareNameCh = selRow.declareNameCh;
			//   // row.declareNameEn = selRow.declareNameEn;
			//   row.goodsNameEn = selRow.goodsNameEn;
			//   row.declarePrice = selRow.declarePrice;

			//   // defCode: '', //this.$t('i18nn_791e36963e37617a'),
			//   // hashCode: '', //this.$t('i18nn_198738759379a3c0'),
			//   // declareNameCh: '', //this.$t('i18nn_73d237ea940222ce'),
			//   // declareNameEn: '', //this.$t('i18nn_9894786aad05f849'),
			//   // declarePrice: '', //this.$t('i18nn_fe28507421c4c3b3'),

			//   this.$set(this.form.packRecordList, this.openRowIndex, row);

			//   //计算
			//   // this.CalculateTotalWeAndVo(row,this.openRowIndex);
			//   // this.openRowData = row;
			//   // this.openRowIndex = index;
			//   // this.$nextTick(() => {
			//   //   this.$refs.hyUpLoadImg1.openFile();
			//   // });
			// },

			// hyUpLoadImg1: function(childValue) {
			//   // childValue就是子组件传过来的值
			//   this.form.goodsImg = childValue;
			// },

			//查看图片
			// openBigImg(event, imgsrc) {
			//   event.stopPropagation();
			//   console.log('openBigImg', imgsrc);
			//   this.dialogImgVisible = true;
			//   this.imgUrlBigShow = imgsrc;
			// },

			//点击状态为非出账弹出窗
			// noConfirm(){
			//   this.$alert(this.$t('i18nn_e741d17b1fd891c2')已出账this.$t('i18nn_d6f109b7bee2f33f')确认"操作，请核实！', this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			// },
			//提交信息
			// submitForm(formName) {
			//   this.$refs[formName].validate(valid => {
			//     if (valid) {
			//       let formData = Object.assign({}, this.form);
			//       //浅拷贝、对象属性的合并
			//       if (0 === this.dialogFormStatus) {
			//         formData.id = null;
			//         formData.userId = this.UserInfo.id;

			//         this.postData(this.$urlConfig.WhPackingListUpdate, formData);
			//       } else {
			//         formData.userId = this.UserInfo.id;
			//         this.postData(this.$urlConfig.WhPackingListUpdate, formData);
			//       }
			//     } else {
			//       console.log('error submit!!');
			//       this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
			//         type: 'warning'
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			//       return false;
			//     }
			//   });
			// },
			//重置输入框
			// resetForm(formName) {
			//   console.log(formName);
			//   // console.log(this.$refs[formName]);

			//   this.$nextTick(() => {
			//     if (this.$refs[formName]) {
			//       this.$refs[formName].resetFields();
			//       if ('form' === formName) {
			//         this[formName]['packRecordList'] = [];
			//       }
			//     } else {
			//       console.log('this.$refs[formName]', this.$refs[formName]);
			//     }
			//   });
			// },

			//提交信息
			postData(url, formData, callback, type) {
				// HttpTypelet _this = this;
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);

					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						this.dialogFormVisible = false;
						this.getPageData();
						callback();
						// if("delete"==type){
						// 	this.$message({
						// 	    type: 'success',
						// 	    message: this.$t('tips.successfullyDelete')
						// 	});
						// } else {
						// 	this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
						// 		type: 'success'
						// 	});
						// }
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},
			toCreate() {
				this.$router.push({
					name: "createOrder",
					query: {
						orderType: '60'
					}
				})
			},
			//打开导入弹窗
			//  openExcelDialog() {
			//    this.dialogUploadVisible = true;
			//    this.excelData = [];
			//    this.fileExcel = null;
			//    this.excelFileName = '';


			//    // this.excelFileName = "";
			//    // this.$refs.file.value = '';
			// this.$nextTick(_=>{
			//   try{
			//     this.$refs.file.value = '';
			//   }catch(e){
			//     console.log(e);
			//     //TODO handle the exception
			//   }
			// })
			//  },
			// fileChange(e) {
			//   // this.loading = true;
			//   console.log(e);
			//   this.fileExcel = null;
			//   this.excelFileName = '';
			//   console.log(this.$t('i18nn_f156a9536049f461'), this.$refs.file);
			//   this.excelData = [];
			//   if (!this.$refs.file || !this.$refs.file.files) {
			//     this.$alert(this.$t('module.upload.Unknown_file'), this.$t('tips.tipsTitle'), {
			//       // //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     });
			//     return;
			//   }
			//   if (this.$refs.file.files[0]) {
			//     // if (this.fileAccept.indexOf(this.$refs.file.files[0].type) > -1) {
			//     if (this.$refs.file.files[0].size < 1024 * 1024 * 10) {
			//       this.fileExcel = this.$refs.file.files[0];
			//       this.excelFileName = this.fileExcel.name;
			//       // console.log(this.$t('i18nn_f156a9536049f461'),this.$refs.file);
			//       // this.uploadExcel();
			//     } else {
			//       this.$alert(this.$t('module.upload.uploaded_over')+'10M', this.$t('tips.tipsTitle'), {});
			//     }
			//     // } else {
			//     //   this.$alert(this.$t(this.$t('i18nn_4dadf13ebce2167d')), this.$t('tips.tipsTitle'), {
			//     //   });
			//     // }
			//   } else {
			//     // this.$alert(this.$t('i18nn_c8e99e36cefd743a'), this.$t('tips.tipsTitle'), {
			//     // });
			//     console.log(this.$t('i18nn_c8e99e36cefd743a'), this.$refs.file);
			//   }
			// },
			//  openExcel() {
			// try{
			//   this.$refs.file.value = '';
			// }catch(e){
			//   console.log(e);
			//   //TODO handle the exception
			// }
			//    // this.$refs.file.value = '';
			//    // this.$refs.file.dispatchEvent(new MouseEvent('click'));
			//    var comment = this.$refs.file;
			//    if (document.all) {
			//      // For IE
			//      comment.click();
			//    } else if (document.createEvent) {
			//      // 在ff中要为a标签添加click事件，并且侦听该事件
			//      var ev = document.createEvent('MouseEvents');
			//      ev.initEvent('click', false, true);
			//      comment.dispatchEvent(ev);
			//    } else {
			//      // this.$alert('打开上传文件有误，请联系客服', this.$t('tips.tipsTitle'), {
			//      this.$alert(this.$t('module.upload.open_error_img'), this.$t('tips.tipsTitle'), {
			//        // //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//      });
			//    }
			//  },
			//导入excel
			// uploadExcel() {
			//   if (!this.fileExcel) {
			//     this.$alert(this.$t('module.upload.Please_sel_file'), this.$t('tips.tipsTitle'), {});
			//     return;
			//   }

			//   this.loadingExcel = true;

			//   let file = this.fileExcel;
			//   var formdata = new FormData();
			//   formdata.append('file', file);

			//   this.$http
			//     .post(this.$urlConfig.WhPackingListExUpload, formdata, {
			//       headers: {
			//         'Content-Type': 'multipart/form-data'
			//       }
			//     })
			//     .then(({ data }) => {
			//       console.log(this.$t('i18nn_bc868e024b80d2e3'));
			//       console.log(data);
			//       this.loadingExcel = false;
			//       if (200 == data.code && data.rows) {
			//         this.excelData = data.rows;
			//         // if('1'==data.data.flag){
			//         //   this.excelData = data.data.data;
			//         // } else {
			//         //   this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
			//         //     type: 'warning',
			//         //     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//         //   });
			//         // }
			//       } else {
			//         this.$alert(data.msg ? data.msg : this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
			//           type: 'warning'
			//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//         });
			//       }
			//       // if (200 == data.code && data.data) {
			//       //   if('1'==data.data.flag){
			//       //     this.excelData = data.data.data;
			//       //   } else {
			//       //     this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
			//       //       type: 'warning',
			//       //       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       //     });
			//       //   }

			//       // } else {
			//       //   this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
			//       //     type: 'warning',
			//       //     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       //   });
			//       // }
			//     })
			//     .catch(error => {
			//       console.log(error);
			//       console.log(this.$t('tips.submitError'));
			//       this.loadingExcel = false;
			//       this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
			//         type: 'warning'
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			//     });
			// },
			//提交-导入excel
			// submitExcelForm() {
			//   this.loadingExcel = true;
			//   this.$http
			//     .put(this.$urlConfig.WhPackingListExSubmit, this.excelData)
			//     .then(({ data }) => {
			//       console.log(this.$t('i18nn_bc868e024b80d2e3'));
			//       console.log(data);
			//       this.loadingExcel = false;
			//       if (200 == data.code) {
			//         if (data.data) {
			//           // this.excelData = data.data.data;
			//           this.dialogUploadVisible = false;
			//           this.excelData = [];
			//           this.excelFileName = '';
			//           this.fileExcel = null;
			//           this.initData();
			//           this.$alert(this.$t('module.upload.Import_success'), this.$t('tips.tipsTitle'), {
			//             type: 'success'
			//             //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//           });
			//         } else {
			//           this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
			//             type: 'warning'
			//             //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//           });
			//         }
			//       } else {
			//         this.$alert(data.msg ? data.msg : this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
			//           type: 'warning'
			//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//         });
			//       }
			//     })
			//     .catch(error => {
			//       console.log(error);
			//       console.log(this.$t('tips.submitError'));
			//       this.loadingExcel = false;
			//       this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
			//         type: 'warning'
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			//     });
			// },

			//查询数据
			// serPageData() {
			//   this.pagination.current_page = 1;
			//   this.getPageData();
			// },
			handleSelectStatus(key, keyPath) {
				// console.log(key, keyPath);
				this.filterData.status = key;
				this.initData();
			},

			//格式换待导出去数据
			formatterData(arr) {
				let newArr = [];
				arr.forEach(item => {
					if (item.packRecordList && item.packRecordList.length > 0) {
						// {
						// 	title: 'SKU',
						// 	key: 'goodsSku'
						// },
						// {
						// 	title: this.$t('b9d4d3f6183bf3c1'),
						// 	key: 'packCount'
						// },
						// {
						// 	title: this.$t('c9fbcb0263c9b1f2'),
						// 	key: 'verifyCount'
						// },
						// {
						// 	title: this.$t('i18nn_3e8b33480ff7e0f7'),
						// 	key: 'totalCTN'
						// },
						// {
						// 	title: this.$t('i18nn_83125000f5142ed1'),
						// 	key: 'totalPallet'
						// },
						// {
						// 	title: this.$t('i18nn_85a10d3e5ea0e103'),
						// 	key: 'ctnPCS'
						// },
						// {
						// 	title: this.$t('b6bf0a07fe26f74f'),
						// 	key: 'ctnSizeUnitName'
						// },
						// {
						// 	title: this.$t('i18nn_f8fdea45f59d1627'),
						// 	key: 'ctnWeight'
						// },
						// {
						// 	title: this.$t('i18nn_913e044950ff558b')+'L*W*H',
						// 	key: 'ctnLWH'
						// },
						item.packRecordList.forEach(item2 => {
							let ctnLWH = '';
							// if (item2.mySkuDto) {
							ctnLWH = item2.ctnLength + ' * \n' + item2.ctnWidth + ' * \n' +
								item2.ctnHeight;
							// }
							newArr.push(Object.assign({}, item, {

								goodsSku: item2.goodsSku,
								packCount: item2.packCount,
								verifyCount: item2.verifyCount,
								totalCTN: item2.totalCTN,
								totalPallet: item2.totalPallet,

								ctnPCS: item2.ctnPCS,
								ctnSizeUnitName: item2.ctnSizeUnitName,
								ctnWeight: item2.ctnWeight,
								ctnLWH: ctnLWH,
								// mergeLength: item.sendRecordDtoList.length?item.sendRecordDtoList.length:1;
							}));
						})
					} else {
						newArr.push(item);
					}
				});
				console.log('newArr', newArr);
				return newArr;
			},

			//分页的筛选项数据
			pageFilterData() {

				let commitDateArr = this.filterData.commitDateArr;
				let startCommitDate = '';
				let endCommitDate = '';
				if (commitDateArr && commitDateArr.length == 2) {
					startCommitDate = commitDateArr[0];
					endCommitDate = commitDateArr[1];
				}
				
				let packingNoList = [];
				
				if (this.filterData.packingNoListStr) {
					packingNoList = this.filterData.packingNoList;
				} else if (this.filterData.packingNo) {
					packingNoList = [this.filterData.packingNo];
				}

				return {
					// workNo: this.filterData.workNo ? this.filterData.workNo : null,
					proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					status: this.filterData.status ? this.filterData.status : null,
					// packingNo: this.filterData.packingNo ? this.filterData.packingNo : null,
					packingNoList: packingNoList.length > 0 ? packingNoList : null,
					
					keyword: this.filterData.keyword ? this.filterData.keyword : null,
					// // putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
					// whNo: this.filterData.whNo ? this.filterData.whNo : null,
					zoneWhNo: this.filterData.whNo ? this.filterData.whNo : null,

					startCommitDate: startCommitDate ? startCommitDate : null,
					endCommitDate: endCommitDate ? endCommitDate : null,
					// hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
					// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
				};
			},

			//请求分页数据
			getPageData() {

				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size //当前页显示数目
					},
					this.pageFilterData()
				);

				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhPackingListPageList, filterData)
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);

						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

			//新增
			// addLe2() {
			//   console.log('this.form', this.form);
			//   this.form.packRecordList.push({
			//     goodsSku: '', //this.$t('i18nn_31f887c1ada79a99'),
			//     // cusCode: '', //this.$t('i18nn_64fed2851f79d875'),
			//     goodsName: '', //this.$t('i18nn_b23004db5349dfd2'),
			//     goodsNameEn: '', //this.$t('i18nn_05141ac0139eaf01'),
			//     goodsImg: '', //this.$t('i18nn_877c5a0e44a0eb07'),
			//     // defCode: '', //this.$t('i18nn_791e36963e37617a'),
			//     // hashCode: '', //this.$t('i18nn_198738759379a3c0'),
			//     // declareNameCh: '', //this.$t('i18nn_73d237ea940222ce'),
			//     // declareNameEn: '', //this.$t('i18nn_9894786aad05f849'),
			//     // declarePrice: '', //this.$t('i18nn_fe28507421c4c3b3'),
			//     goodsWeight: '', //this.$t('i18nn_96f1a4b17e75892d'),
			//     sizeUnit: '', //this.$t('i18nn_38bc71c0dc55904b'),
			//     // weightUnit: '', //this.$t('i18nn_8e01866868930d09'),
			//     goodsLength: '', //this.$t('i18nn_2cba96917484569a'),
			//     goodsWidth: '', //this.$t('i18nn_9e24e0e4745f4948'),
			//     goodsHeight: '', //this.$t('i18nn_c5fa0857bc0df2d6'),
			//     safeStock: '', //this.$t('i18nn_004617dc47191cb8'),
			//     packCount: null, //this.$t('i18nn_b9d4d3f6183bf3c1'),
			//     proxyCus: '' //货代客人
			//     // "id":"",//"数据id"
			//   });
			//   this.$forceUpdate();
			// },
			// //删除
			// delLe2(index) {
			//   this.form.packRecordList.splice(index, 1);
			// },
			//
			showDetList($event, row) {
				$event.stopPropagation();
				// this.dialogShowDet = true;
				// this.packId = row.id;
				this.rowDet = row;
				this.openTimeDet = new Date().getTime();
			},
			//操作
			// sureBillAction(row,msg,state) {
			//   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
			//   console.log(row);
			//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
			//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			//     type: 'warning'
			//   }).then(() => {
			//     //确认
			//     let dataParm = {
			//       ids:[row.id],
			//       state:state,
			//     }
			//     this.updatePageData(dataParm, msg);
			//   }).catch(() => {

			//   });

			// },
			//更新特定数据,
			// updatePageData(dataParm, msg) {
			//   // let _this = this;
			//   this.loading_load = true;
			//   //浅拷贝、对象属性的合并
			//   let dataParmAss = Object.assign({}, dataParm);
			//   // dataParmAss.isDefault = 1;
			//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
			//     .then(({ data }) => {
			//       console.log(msg + "特定数据，请求成功");
			//       console.log(data);
			//       if (200 == data.code) {
			//         this.$message.success(msg + '成功！');
			//         this.getPageData();
			//       } else {
			//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log("更新特定数据，请求失败");
			//       this.loading_load = false;
			//       this.$message.warning('' + msg + '失败,请重试！');
			//     });
			// },
			//查询数据字典
			// getDicData() {
			//   // let _this = this;
			//   // console.log(keyword);

			//   // this.loading_load = true;
			//   this.$http
			//     .put(this.$urlConfig.HyDicQueryList, ['wh_no'])
			//     .then(({ data }) => {
			//       console.log('查询数据字典，请求成功');
			//       console.log(data);
			//       if (200 == data.code && data.data) {
			//         // this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			//         // this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			//         // this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];

			//   //       this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			//   //       this.selectOption.wh_packing_type = data.data['wh_packing_type'];
			// 		// this.selectOption.wh_container_size = data.data['wh_container_size'];
			// 		this.selectOption.wh_no = data.data['wh_no'];
			//       } else {
			//         if (!data.msg) {
			//           data.msg = this.$t("tips.submitError");
			//         }
			//         this.$message.warning(data.msg);
			//       }
			//     })
			//     .catch(error => {
			//       console.log(error);
			//       console.log('查询数据字典接口，请求失败');
			//       this.$message.error(this.$t("tips.submitRequestError"));
			//     });
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>