<template>
	<div class="">
		<el-drawer :wrapperClosable="false" :title="$t('FormMsg.details_View')" append-to-body :visible.sync="dialogVisible"
			:direction="'rtl'" size="1200px">

			<el-tabs v-model="detTabActiveName" type="border-card">
				<el-tab-pane label="" name="first" v-loading="loading">
					<span slot="label"><i class="el-icon-document"></i> <span>{{$t('i18nn_e6a250894a04db4c')}}</span></span>
					<!-- <div class="tableConTop">
					<el-row>
						<el-col :span="5" class="tableConTopLeft">
							<h3>
								<span class="tct_tit">{{$t('i18nn_3dc377fecc5acdb1')}}</span>
							</h3>
						</el-col>
						<el-col :span="19" class="tableConTopRig">
							<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
							</el-button>
						</el-col>
					</el-row>
				</div> -->

					<el-card shadow="never" style="margin-bottom: 5px;">
						<!-- <div style="display: flex;justify-content: space-between;"> -->
						<!-- <div style="flex:1"> -->
						<el-descriptions title="">
							<el-descriptions-item :label="$t('hytxs0000060')">{{baseData.cusName}}</el-descriptions-item>
							<el-descriptions-item :label="$t('i18nn_35ec768075eb8f4e')">{{baseData.receiptNo}}</el-descriptions-item>
							<el-descriptions-item
								:label="$t('Storage.packing_list._no')">{{baseData.packingNo}}</el-descriptions-item>
							<el-descriptions-item :label="$t('i18nn_b799f516e3940e3f')">
								<!-- {{baseData.packageTypeName}} -->
								<span>{{baseData.packageTypeName}}</span>

							</el-descriptions-item>
							<el-descriptions-item :label="$t('i18nn_108c7dc6debca017')" v-if="'1'==baseData.packageType">
								<!-- {{baseData.containerSizeName}} -->
								<span>{{baseData.containerSizeName}}</span>


							</el-descriptions-item>
						</el-descriptions>
						<!-- </div> -->

						<!-- <div style="width: 300px; text-align:right;">
						<el-button type="primary" v-if="baseData.status=='10'" @click="submitExcelFormAction()" icon="el-icon-position" size="small">{{$t('hytxs0000005')}}</el-button>
						<el-button type="primary" v-else @click="submitExcelFormAction()" icon="el-icon-position" size="small">{{$t('i18nn_d6e8213d96d6018d')}}</el-button>
						
					</div> -->
						<!-- </div> -->
					</el-card>

					<el-card shadow="never" v-loading="loading_load" :body-style="{'padding':'0'}" style="margin-bottom: 5px;">
						<div slot="header" class="">
							<div style="display: flex;justify-content: space-between;">
								<div>
									<!-- <i class="el-icon-finished"></i> -->
									<h3><span>{{$t('i18nn_3dc377fecc5acdb1')}}</span><el-badge :value="tableDataTotal"></el-badge></h3>
									<!-- <el-button @click="authAction($event, null)" type="warning" size="small"
								icon="el-icon-check" v-if="2==showType">{{$t('i18nn_695d318c3b1ae5ea')}}</el-button> -->
								</div>
							</div>
						</div>
						<div class="">

							<el-table ref="multipleTable" :data="tableData" :max-height="$store.state.frameConHeightWh4" stripe
								:border="true" style="width: 100%" size="small">
								<el-table-column type="index" fixed="left" width="50" align="center"
									:label="$t('Storage.tableColumn.no')"></el-table-column>
								

								<el-table-column prop="verifyStatus" :label="$t('i18nn_43c20b6ea93b4b20')">
									<template slot-scope="scope">
										<el-tag type=""
											v-if="'0'==scope.row.verifyStatus">{{ $Utils.i18nKeyText(scope.row,'verifyStatusName') }}</el-tag>
										<el-tag type="success"
											v-else-if="'1'==scope.row.verifyStatus">{{ $Utils.i18nKeyText(scope.row,'verifyStatusName') }}</el-tag>
										<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'verifyStatusName') }}</el-tag>

									</template>
								</el-table-column>

								<el-table-column prop="statusName" :label="'状态'" width="">
									<template slot-scope="scope">
										<div>
											<el-tag type="" size="mini"
												v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
											<el-tag type="danger" size="mini"
												v-else-if="'20' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
											<el-tag type="warning" size="mini"
												v-else-if="'40' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
											<el-tag type="success" size="mini"
												v-else-if="'60' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
											<el-tag type="info" size="mini"
												v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
										</div>
									</template>
								</el-table-column>
								<el-table-column>
									<template :slot="'header'">
										SKU<span>{{$t('i18nn_8e2be23dd3b81a31')}}</span>
									</template>
									<el-table-column prop="goodsSku" label="SKU" :label-class-name="'label-class-name-s1'"
										min-width="150">
										<template slot-scope="scope">
											<div>
												<!-- <el-link type="primary" size="mini" icon="" @click="OpenViewSkuOpen($event, scope.row)">
													{{ scope.row.goodsSku }}
												</el-link> -->

												<div class="link-copy">
													<span class="link-text" @click="OpenViewSkuOpen($event, scope.row, scope.$index)">
														{{ scope.row.goodsSku }}
													</span>
													<el-tooltip class="link-copy-icon" effect="dark" :content="$t('i18nn_29f67f0644a78bf3')"
														placement="top">
														<i class="el-icon-document-copy" @click="$Utils.copyText(scope.row.goodsSku)"></i>
													</el-tooltip>
												</div>
											</div>
										</template>
									</el-table-column>

									<el-table-column prop="packCount" :label="$t('i18nn_72d35d210669abb2')"
										:label-class-name="'label-class-name-s1'"></el-table-column>
									<el-table-column prop="verifyCount" :label="$t('i18nn_4567a206c45dc3f4')"
										:label-class-name="'label-class-name-s1'" min-width="">
										<template slot-scope="scope">
											<div>
												<div v-if="scope.row.verifyCount">
													<el-tag type="danger"
														v-if="scope.row.packCount != scope.row.verifyCount">{{ scope.row.verifyCount }}</el-tag>
													<el-tag type="success" v-else>{{ scope.row.verifyCount }}</el-tag>
												</div>
											</div>
										</template>
									</el-table-column>


									<!-- <el-table-column prop="inWhCount" :label="'上架数量'" :label-class-name="'label-class-name-s1'">
										<template slot-scope="scope">
											<div>
												{{ scope.row.inWhCount }}
											</div>
										</template>
									</el-table-column> -->

									<!-- <el-table-column prop="goodsName" :label="'SKU名称'" width="200" show-overflow-tooltip :label-class-name="'label-class-name-s1'">
						<template slot-scope="scope">
							<div>
								<div>{{scope.row.goodsName}}</div>
								<div>{{scope.row.goodsNameEn}}</div>
							</div>
						</template>
					</el-table-column> -->

									<!-- <el-table-column prop="goodsWeight" :label="$t('Storage.skuInfo.weight')" :label-class-name="'label-class-name-s1'"></el-table-column>
				
					<el-table-column prop="" :label="'SKU体积(L*W*H)'" :label-class-name="'label-class-name-s1'">
						<template slot-scope="scope">
							<div>
								<span>{{scope.row.goodsLength}}</span>*
								<span>{{scope.row.goodsWidth}}</span>*
								<span>{{scope.row.goodsHeight}}</span>
							</div>
						</template>
					</el-table-column>
				
					<el-table-column prop="sizeUnitName" :label="$t('Storage.skuInfo.unit')" :label-class-name="'label-class-name-s1'"></el-table-column>
				 -->
								</el-table-column>

								<el-table-column :label="$t('i18nn_3e8b33480ff7e0f7')">
									<el-table-column prop="totalCTN" :label="$t('i18nn_e7b838a996147e21')" min-width="">
										<template slot-scope="scope">
											<div>
												<span>{{scope.row.totalCTN}}</span>
											</div>
										</template>
									</el-table-column>
									<el-table-column prop="totalPallet" :label="$t('i18nn_83125000f5142ed1')" min-width="">
										<template slot-scope="scope">
											<div>
												<span>{{scope.row.totalPallet}}</span>
											</div>
										</template>
									</el-table-column>
									<el-table-column prop="ctnPCS" :label="$t('i18nn_85a10d3e5ea0e103')"></el-table-column>
									<el-table-column prop="ctnSizeUnitName" :label="$t('i18nn_b6bf0a07fe26f74f')"></el-table-column>
									<el-table-column prop="ctnWeight" :label="$t('i18nn_89d99a1a68608f3b')" min-width="120">
										<template slot-scope="scope">
											<div>
												<span>{{scope.row.ctnWeight}}</span>
											</div>
										</template>
									</el-table-column>
									<!-- <el-table-column prop="ctnLength" :label="$t('i18nn_eb8af1b7e16e2069')"></el-table-column>
					<el-table-column prop="ctnWidth" :label="$t('i18nn_64e6eb209a8a2cff')"></el-table-column>
					<el-table-column prop="ctnHeight" :label="$t('i18nn_2a02dbcab12c9ee8')"></el-table-column> -->

									<el-table-column prop="" min-width="120">
										<template :slot="'header'">
											<span>{{$t('i18nn_913e044950ff558b')}}</span>(L*W*H)
										</template>
										<template slot-scope="scope">
											<div>
												<div>
													<span>{{scope.row.ctnLength}}</span>*
													<span>{{scope.row.ctnWidth}}</span>*
													<span>{{scope.row.ctnHeight}}</span>
												</div>
											</div>
										</template>
									</el-table-column>
								</el-table-column>




								<!-- <el-table-column prop="goodsImg" :label="$t('Storage.skuInfo.SKU_pictures')">
				  <template slot-scope="scope">
				    <div>
				      <el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.goodsImg" @click="openBigImg($event, scope.row.goodsImg)">
				        <div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i></div>
				      </el-image>
				    </div>
				  </template>
				</el-table-column>
				
        <el-table-column prop="declarePrice" :label="$t('Storage.skuInfo.price')"></el-table-column>
        <el-table-column prop="goodsWeight" :label="$t('Storage.skuInfo.weight')"></el-table-column>

        <el-table-column prop="sizeUnitName" :label="$t('Storage.skuInfo.unit')"></el-table-column>

        <el-table-column prop="goodsLength" :label="$t('Storage.skuInfo.length')"></el-table-column>
        <el-table-column prop="goodsWidth" :label="$t('Storage.skuInfo.width')"></el-table-column>
        <el-table-column prop="goodsHeight" :label="$t('Storage.skuInfo.height')"></el-table-column> -->



								<!-- <el-table-column prop="totalWeight" :label="$t('i18nn_ea71165e067be24e')"></el-table-column> -->
								<!-- <el-table-column prop="totalVol" :label="$t('i18nn_5ada761d1917f8bc')"></el-table-column> -->
								<!-- <el-table-column prop="safeStock" :label="$t('Storage.skuInfo.safety_stock')"></el-table-column> -->

								<!-- <el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
							<template slot-scope="scope">
								<el-popover placement="top" trigger="hover">
									<div class="pre-text">{{ scope.row.remark }}</div>
									<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
								</el-popover>
							</template>
						</el-table-column> -->

								<!-- <el-table-column prop="goodsSku" label="SKU">

        </el-table-column> -->

								<!-- <el-table-column prop="cusCode" :label="$t('i18nn_64fed2851f79d875')"></el-table-column> -->

								<!-- <el-table-column prop="goodsName" :label="$t('i18nn_a386a6933d52dad8')">

        </el-table-column> -->

								<!-- <el-table-column prop="goodsNameEn" :label="$t('i18nn_05141ac0139eaf01')">

        </el-table-column> -->

								<!-- <el-table-column prop="goodsImg" :label="$t('Storage.skuInfo.SKU_pictures')">
          <template slot-scope="scope">
            <div>
              <el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.goodsImg" @click="openBigImg($event, scope.row.goodsImg)">
                <div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i></div>
              </el-image>
            </div>
          </template>
        </el-table-column> -->

								<!-- <el-table-column prop="defCode" :label="$t('i18nn_791e36963e37617a')"></el-table-column> -->
								<!-- <el-table-column prop="hashCode" :label="$t('i18nn_198738759379a3c0')"></el-table-column> -->
								<!-- <el-table-column prop="declareNameCh" :label="$t('i18nn_73d237ea940222ce')"></el-table-column> -->
								<!-- <el-table-column prop="declareNameEn" :label="$t('i18nn_9894786aad05f849')"></el-table-column> -->
								<!-- <el-table-column prop="declarePrice" :label="$t('Storage.skuInfo.price')"></el-table-column> -->

								<!-- <el-table-column prop="packCount" :label="$t('i18nn_5cedfd4fa0b91b4f')"></el-table-column> -->


								<!-- <el-table-column prop="goodsWeight" :label="$t('i18nn_11e41b2ec3d872ed')"></el-table-column>
        <el-table-column prop="weightUnitName" :label="$t('i18nn_8e01866868930d09')"></el-table-column>
        <el-table-column prop="goodsLength" :label="$t('Storage.skuInfo.length')"></el-table-column>
        <el-table-column prop="goodsWidth" :label="$t('Storage.skuInfo.width')"></el-table-column>
        <el-table-column prop="goodsHeight" :label="$t('Storage.skuInfo.height')"></el-table-column>
        <el-table-column prop="volumeUnitName" :label="$t('i18nn_57936a4767ded7f5')"></el-table-column> -->
								<!-- <el-table-column prop="whFeeTypeName" :label="$t('i18nn_ebe6f6c25130b09a')"></el-table-column> -->
								<!-- <el-table-column prop="whFeeAmt" :label="$t('i18nn_fe66b0deddea04d6')"></el-table-column> -->
								<!-- <el-table-column prop="putWhCount" :label="$t('i18nn_3df520fd9dc4bdeb')"></el-table-column> -->
								<!-- <el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')"></el-table-column> -->

								<!-- <el-table-column :label="$t('Storage.tableColumn.operation')" width="200px" fixed="right">
          <template slot-scope="scope">
            <div v-if="isSel"><el-button @click="selRow($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-magic-stick">{{$t("FormMsg.Select")}}</el-button></div>
            <div v-else>
              <el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button>
              <el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button>
              </div>
          </template>
        </el-table-column> -->
							</el-table>


							<!-- <div class="tableCon_pagination">
								<hy-page-pagination :pagination='pagination' v-on:updatePageData="getPageData"></hy-page-pagination>
							</div> -->
						</div>
					</el-card>

					<el-card shadow="never" :body-style="{'padding':'0'}">
						<div slot="header" class="">
							<!-- <h3> -->
							<!-- <i class="el-icon-folder"></i> -->
							<h3>
								<!-- <span>已上传的附件</span> -->
								<span>{{$t('i18nn_a7e730af52ab52b1')}}</span>
								<span>{{$t('i18nn_84adf48acbd6ad24')}}</span>
								<el-badge :value="tableDataFileTotal"></el-badge>
							</h3>
							<!-- </h3> -->
						</div>
						<div>
							<el-table ref="multipleTable" :data="tableDataFile" stripe style="width: 100%" size="small"
								:max-height="$store.state.frameConHeightWh4" v-loading="loadingUpload">
								<el-table-column type="index" fixed="left" width="50" align="center"
									:label="$t('Storage.tableColumn.no')"></el-table-column>
								<el-table-column prop="fileName" :label="$t('i18nn_ea4756bc1642e0f1')"></el-table-column>
								<el-table-column :label="$t('i18nn_2674282277c3714e')">
									<template slot-scope="scope">
										<el-image style="width: 100px; height: 100px" :z-index="9999" :fit="'contain'" :src="scope.row.url"
											:preview-src-list="tableDataFile.map(itemPre=> { return itemPre.url})">
											<div slot="error" class="image-slot">
												<i class="el-icon-document"></i>
											</div>
										</el-image>
									</template>
								</el-table-column>
								<el-table-column prop="typeName" :label="$t('184333c81babf2f1')"></el-table-column>
								<el-table-column prop="url" :label="$t('i18nn_d54012286fece209')" show-overflow-tooltip>
									<template slot-scope="scope">
										<a :href="scope.row.url" target="_blank">{{ scope.row.url }}</a>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</el-card>

				</el-tab-pane>
				<el-tab-pane label="" name="second">
					<span slot="label"><i class="el-icon-coin"></i> <span>{{$t('i18nn_d7315fb8114eb446')}}</span></span>

					<FeeModelById :id="packId"></FeeModelById>
				</el-tab-pane>
			</el-tabs>
		</el-drawer>

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
      <div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogImgVisible = false">{{$t('FormMsg.Close')}}</el-button></div>
    </el-dialog> -->

		<!-- 查看SKU -->
		<dialogSkuView :openTime="ViewSkuOpenTime" :goodsSku="ViewSkuSku"></dialogSkuView>
	</div>
</template>
<script>
	import dialogSkuView from '@/components/StorageCenter/WSkuProduct/dialogSkuView.vue';
	import FeeModelById from '@/components/StorageCenter/Other/FeeModelById.vue';
	export default {

		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		props: {
			openTime: {

			},
			row: {}
			// packId: {
			// 	default: function() {
			// 		return ''
			// 	},
			// 	type: String
			// },
		},
		components: {
			dialogSkuView,
			FeeModelById
		},
		data() {
			return {
				detTabActiveName: 'first',
				// UserInfo: this.$store.getters.getUserInfo,
				dialogVisible: false,
				packId: '',
				baseData: {},

				tableDataFile: [],
				loadingUpload: false,
				tableDataFileTotal: '',

				dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改

				ViewSkuOpenTime: false,
				ViewSkuSku: "",

				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',

				loading: false,
				// form: {
				//     "goodsSku": "",//this.$t('i18nn_31f887c1ada79a99'),
				//     "cusCode":"",//this.$t('i18nn_64fed2851f79d875'),
				//     "goodsName":"",//this.$t('i18nn_b23004db5349dfd2'),
				//     "goodsNameEn":"",//this.$t('i18nn_05141ac0139eaf01'),
				//     "goodsImg":"",//this.$t('i18nn_877c5a0e44a0eb07'),
				//     "defCode":"",//this.$t('i18nn_791e36963e37617a'),
				//     "hashCode":"",//this.$t('i18nn_198738759379a3c0'),
				//     "declareNameCh":"",//this.$t('i18nn_73d237ea940222ce'),
				//     "declareNameEn":"",//this.$t('i18nn_9894786aad05f849'),
				//     "declarePrice":"",//this.$t('i18nn_fe28507421c4c3b3'),
				//     "goodsWeight":"",//this.$t('i18nn_11e41b2ec3d872ed'),
				//     "weightUnit":"",//this.$t('i18nn_8e01866868930d09'),
				//     "goodsLength":"",//this.$t('i18nn_2cba96917484569a'),
				//     "goodsWidth":"",//this.$t('i18nn_9e24e0e4745f4948'),
				//     "goodsHeight":"",//this.$t('i18nn_c5fa0857bc0df2d6'),
				//     "id":"",//"数据id"
				// },

				// formRules: {
				//   goodsSku: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   cusCode: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   goodsName: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   goodsNameEn: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   goodsImg: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }],
				//   defCode: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   hashCode: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   declareNameCh: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   declareNameEn: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   declarePrice: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   goodsWeight: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   weightUnit: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }],
				//   goodsLength: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   goodsWidth: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   goodsHeight: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   volumeUnit: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }],
				//   whFeeType: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   whFeeAmt: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// },
				loading_load: false,
				tableData: [],
				tableDataTotal: '',
				//分页数据
				// pagination: this.$Utils.defaultPagination(),
				// selectOption: {
				//   wh_weight_unit: [],
				//   wh_vol_unit: [],
				//   wh_goods_fee_type:[],
				//   statusList: [
				//     {
				//       value: '',
				//       label: this.$t('i18nn_16853bda54120bf1')
				//     },
				//     {
				//       value: '1',
				//       label: this.$t('i18nn_fdd8426d7b8869c5')
				//     },
				//     {
				//       value: '2',
				//       label: this.$t('i18nn_619d7e563f879811')
				//     },
				//     {
				//       value: '3',
				//       label: this.$t('i18nn_1dfb3a065d3ffe39')
				//     }
				//   ]
				// },
				//查询，排序方式
				// filterData: {
				// "orderBy": "id_", //排序字段
				// "sortAsc": "desc", //desc降序，asc升序
				// "accountPeriod":"",
				// packId: "",
				// "billNo":""
				// status: '',
				// putWhNo: '',
				// goodsSku: '',
				// hashCode: '',
				// goodsName: '',
				// declareNameCh: '',
				// }
			}
		},
		// watch: {
		// 	packId: function(newVal, oldVal) {
		// 		console.log('watchKey');
		// 		// if (newVal) {
		// 		// console.log('watch openDateTime HyUpLoadImg.vue');
		// 		this.initData();
		// 		// }
		// 	}
		// },
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogVisible = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();

		},
		//编译挂载前
		mounted() {
			// this.getDicData();

			// this.initData();
		},
		methods: {
			initData() {
				// this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.baseData = this.row;
				this.packId = this.row.id;

				if (this.packId) {
					this.tableData = [];
					this.tableDataTotal = '';
					this.getPageData();
					this.tableDataFile = [];
					this.tableDataFileTotal = '';
					this.getListData(this.packId);
				}
				// this.getDicData();
			},
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//打开新增编辑，弹窗
			// openDioalog(formParm) {
			//   // console.log(formParm);
			//   this.dialogFormVisible = true;
			//   let form = Object.assign({}, formParm);
			//   console.log('form',form);
			//   // // 重置
			//   this.resetForm('form');
			//   if (null === formParm) { //新增

			//     this.form.weightUnit = '1';
			//     this.form.volumeUnit = '1';
			//     this.form.whFeeType = '1';

			//     this.dialogFormStatus = 0;
			//     // form.subUserId = null;
			//     // form.userSubUserId = null;
			//     // form.state = true;
			//     //浅拷贝、对象属性的合并
			//     this.form = form;

			//   } else { //修改
			//     this.dialogFormStatus = 1;
			//     // form.state = form.state === '0' ? true : false;

			//     //浅拷贝、对象属性的合并
			//     this.form = form;

			//   }
			//   // this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			//   //       type: 'warning',
			//   //       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//   //     });
			// },
			//打开编辑
			// openEdit(event, row, index) {
			//   event.stopPropagation();
			//   this.openDioalog(row, this.$t('FormMsg.Edit'));
			// },

			//删除
			// delAction(event, row) {
			//   this.$confirm(this.$t("FormMsg.confirm_Delete"), this.$t('tips.tipsTitle'), {
			//     // confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     // cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			//     type: 'warning'
			//   })
			//     .then(() => {
			//       // this.$message({
			//       //   type: 'success',
			//       //   message: '删除成功!'
			//       // });
			//       this.delDataAction(event, row);
			//     })
			//     .catch(() => {
			//       // this.$message({
			//       //   type: 'info',
			//       //   message: this.$t('i18nn_2e58cb9b52e2a214')
			//       // });
			//     });
			// },
			//删除
			// delDataAction(event, row) {
			//   event.stopPropagation();
			//   console.log('delDataAction', row);
			//   // let parm = [];

			// //   if (!!row) {
			// //     //单条
			// //     parm = [row.id];
			// //   } else {
			// //     //多条
			// // //     let dataList = this.multipleSelection;
			// // //     if (dataList.length < 1) {
			// // //       this.$message.warning(this.$t('i18nn_7b80e66b535a3732'));
			// // //       return;
			// // //     }

			// // //     let dataListParm = dataList.map(v => v.id);
			// // //     parm = dataListParm;
			// //     // console.log('dataListParm', dataListParm);
			// //     // let dataParm = {
			// //     //   ids: dataListParm
			// //     // };
			// //   }

			//   this.postData(this.$urlConfig.WhMySkuDel+'/'+row.id, {}, () => {
			//     this.initData();
			//     //  this.$alert('this.$t('tips.submitSuccess')'tips.submitSuccess')', this.$t('tips.tipsTitle'), {
			//     //   type: 'success',
			//     //   confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     // });
			//     this.$message.success('删除成功！');
			//   });
			// },

			// hyUpLoadImg1: function(childValue) {
			//   // childValue就是子组件传过来的值
			//   this.form.goodsImg = childValue;
			// },
			//查看SKU详情
			OpenViewSkuOpen(event, row) {
				event.stopPropagation();
				this.ViewSkuOpenTime = new Date().getTime();
				this.ViewSkuSku = row.goodsSku;
			},

			//查看图片
			// openBigImg(event, imgsrc) {
			//   event.stopPropagation();
			//   console.log('openBigImg', imgsrc);
			//   this.dialogImgVisible = true;
			//   this.imgUrlBigShow = imgsrc;
			// },

			//点击状态为非出账弹出窗
			// noConfirm(){
			//   this.$alert(this.$t('i18nn_e741d17b1fd891c2')已出账this.$t('i18nn_d6f109b7bee2f33f')确认"操作，请核实！', this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			// },
			//提交信息
			// submitForm(formName) {

			//   this.$refs[formName].validate((valid) => {

			//     if (valid) {
			//       let formData = Object.assign({}, this.form);
			//       //浅拷贝、对象属性的合并
			//       if (0 === this.dialogFormStatus) {

			//         formData.id = null;
			//         formData.userId = this.UserInfo.id;

			//         this.postData(this.$urlConfig.WhMySkuAddUpdate, formData);

			//       } else {

			//         formData.userId = this.UserInfo.id;
			//         this.postData(this.$urlConfig.WhMySkuAddUpdate, formData);
			//       }

			//     } else {
			//       console.log('error submit!!');
			//       this.$alert(this.$t("tips.checkSubmitData"), this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			//       return false;
			//     }
			//   });
			// },
			//重置输入框
			// resetForm(formName) {
			//   console.log(formName);
			//   // console.log(this.$refs[formName]);

			//   if (this.$refs[formName]) {
			//     this.$refs[formName].resetFields();

			//   } else {
			//     console.log('this.$refs[formName]',this.$refs[formName]);
			//   }
			// },

			//提交信息
			// postData(url, formData) {
			//   // let _this = this;
			//   this.loading = true;

			//   // formData.state = formData.state ? '0' : '1';
			//   this.$http.put(url, formData)
			//     .then(({ data }) => {
			//       console.log(this.$t('tips.submitSuccess'));
			//       console.log(data);
			//       
			//       this.loading = false;
			//       if (200 == data.code) {
			//         this.dialogFormVisible = false;
			//         this.getPageData();
			//         this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
			//           type: 'success',
			//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//         });
			//       } else {
			//         // if (!data.msg) {
			//         //   data.msg = this.$t('tips.submitError');
			//         // }
			//         this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
			//           type: 'warning',
			//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//         });
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log(this.$t("tips.submitError"));
			//       this.loading = false;
			//       this.$alert(this.$t("tips.submitRequestError"), this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			//     });
			// },
			//查询数据
			// serPageData() {
			// 	this.pagination.current_page = 1;
			// 	this.getPageData();
			// },
			//请求分页数据
			getPageData() {
				// let _this = this;
				this.loading_load = true;
				this.$http.put(this.$urlConfig.WhPackingListDetPageList, {
						// "sortAsc": this.filterData.sortAsc,
						// "orderBy": this.filterData.orderBy,
						// "offset": (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						// "limit": this.pagination.page_size, //当前页显示数目

						offset: 0, //当前页
						limit: 1000, //当前页显示数目

						packId: this.packId ? this.packId : null,
						// putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
						// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
						// hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
						// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
						// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
					})
					.then(({
						data
					}) => {

						// console.log(this.$store.getters.getUserInfo);
						console.log("分页，请求成功");
						console.log(data);

						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						// this.pagination.total = parseInt(data.total);
						this.tableDataTotal = data.total;
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);


					})
					.catch((error) => {
						console.log(error);
						console.log("分页，请求失败");
						this.loading_load = false;
					});
			},

			//查询 /wh/bizWhFile/query/{relationId}
			getListData(relationId) {
				this.loadingUpload = true;
				this.$http
					.get(this.$urlConfig.WhFileUploadList + '/' + relationId + '/60', {})
					.then(({
						data
					}) => {
						// console.log(this.$t('i18nn_4195755cd53e871a'));
						// console.log(data);
						this.loadingUpload = false;
						if (200 == data.code) {
							// if (data.rows) {
							this.tableDataFile = data.rows;
							this.tableDataFileTotal = data.total;
							// } else {
							//   this.$alert('查询失败,请重试', this.$t('tips.tipsTitle'), {
							//     type: 'warning'
							//     //confirmButtonText: this.$t('204ffab8a6e01870'),
							//   });
							// }
						} else {
							this.$alert(this.$t('i18nn_d74d0bd89431d6d5') + ',' + this.$t('i18nn_4eb3309d6692dbd4'), this.$t(
								'tips.tipsTitle'), {
								type: 'warning'
								//confirmButtonText: this.$t('204ffab8a6e01870'),
							});
						}
					})
					.catch(error => {
						console.log(error);
						// console.log(this.$t('tips.submitError'));
						this.loadingUpload = false;
						this.$alert(this.$t('i18nn_d74d0bd89431d6d5') + ',' + this.$t('i18nn_4eb3309d6692dbd4') + '！', this.$t(
							'tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
					});
			},
			//操作
			// sureBillAction(row,msg,state) {
			//   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
			//   console.log(row);
			//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
			//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			//     type: 'warning'
			//   }).then(() => {
			//     //确认
			//     let dataParm = {
			//       ids:[row.id],
			//       state:state,
			//     }
			//     this.updatePageData(dataParm, msg);
			//   }).catch(() => {

			//   });


			// },
			//更新特定数据,
			// updatePageData(dataParm, msg) {
			//   // let _this = this;
			//   this.loading_load = true;
			//   //浅拷贝、对象属性的合并
			//   let dataParmAss = Object.assign({}, dataParm);
			//   // dataParmAss.isDefault = 1;
			//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
			//     .then(({ data }) => {
			//       console.log(msg + "特定数据，请求成功");
			//       console.log(data);
			//       if (200 == data.code) {
			//         this.$message.success(msg + '成功！');
			//         this.getPageData();
			//       } else {
			//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log("更新特定数据，请求失败");
			//       this.loading_load = false;
			//       this.$message.warning('' + msg + '失败,请重试！');
			//     });
			// },
			//选择数据后回调
			// selRow(event,row) {
			//   event.stopPropagation();
			//   this.$emit('selectRow',row)
			// },
			//查询数据字典
			// getDicData() {
			//   // let _this = this;
			//   // console.log(keyword);

			//   // this.loading_load = true;
			//   this.$http
			//     .put(this.$urlConfig.HyDicQueryList, ['wh_weight_unit', 'wh_vol_unit','wh_goods_fee_type'])
			//     .then(({ data }) => {
			//       console.log('查询数据字典，请求成功');
			//       console.log(data);
			//       if (200 == data.code && data.data) {
			//         this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			//         this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			//         this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			//       } else {
			//         if (!data.msg) {
			//           data.msg = this.$t("tips.submitError");
			//         }
			//         this.$message.warning(data.msg);
			//       }
			//     })
			//     .catch(error => {
			//       console.log(error);
			//       console.log('查询数据字典接口，请求失败');
			//       this.$message.error(this.$t("tips.submitRequestError"));
			//     });
			// }

		}
	}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-input-number,
	// .el-select {

	// 	// width: 100px;
	// 	.el-input__inner {
	// 		text-align: left;
	// 	}
	// }

	// .form_msg {
	// 	color: #e6a23c;
	// }
</style>